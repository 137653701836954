import classNames from 'classnames';
import { Link } from 'react-router-dom';
import useSaleBanner from '../../hooks/useSaleBanner';
import styles from './SalesHeader.module.scss';
import { sendEventV2 } from '../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../utils/analytics/enums';

export interface SalesHeaderProps {
    className?: string;
}

const SalesHeader = ({ className }: SalesHeaderProps) => {
    const { isLoading, isEnabled, config } = useSaleBanner();

    if (isLoading || !isEnabled || !config) {
        return null;
    }

    return (
        <div className={classNames(styles.salesHeader, className)}>
            {config.linkUrl ? (
                <Link
                    className={styles.text}
                    to={config.linkUrl}
                    onClick={handleSalesHeaderClick}
                >
                    {config.title}
                </Link>
            ) : (
                <p className={styles.text} onClick={handleSalesHeaderClick}>
                    {config.title}
                </p>
            )}
        </div>
    );
};

function handleSalesHeaderClick() {
    sendEventV2(
        Page.header,
        Area.salesHeader,
        InteractionItem.link,
        Action.click,
        null
    );
}

export default SalesHeader;
