import { SuggestionType } from './SuggestionType';
import { Suggestion } from './Suggestion';

export const peaks: Suggestion[] = [
    { name: 'Everest Base Camp', type: SuggestionType.Peak, isPopular: true },
    { name: 'Mount Kilimanjaro', type: SuggestionType.Peak, isPopular: true },
    { name: 'Inca Trail', type: SuggestionType.Peak, isPopular: true },
    { name: 'Ayers Rock', type: SuggestionType.Peak, isPopular: false },
    { name: 'Uluru', type: SuggestionType.Peak, isPopular: false },
    { name: 'Mont Blanc', type: SuggestionType.Peak, isPopular: false },
    { name: 'Dolomites', type: SuggestionType.Peak, isPopular: false },
    { name: 'The Alps', type: SuggestionType.Peak, isPopular: false },
    { name: 'Himalayas', type: SuggestionType.Peak, isPopular: false },
    { name: 'Andes', type: SuggestionType.Peak, isPopular: false },
    { name: 'Sierra Nevada', type: SuggestionType.Peak, isPopular: false },
    { name: 'Table Mountain', type: SuggestionType.Peak, isPopular: false },
    { name: 'Mount Toubkal', type: SuggestionType.Peak, isPopular: false },
    { name: 'Annapurna', type: SuggestionType.Peak, isPopular: false },
    { name: 'Lares Trek', type: SuggestionType.Peak, isPopular: false },
    { name: 'Salkantay trek', type: SuggestionType.Peak, isPopular: false },
];
