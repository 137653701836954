import { createContext, ReactNode, useContext, useState } from 'react';

interface HeaderContextValue {
    isMobileMenuOpen: boolean;
    setIsMobileMenuOpen: (value: boolean) => void;
}

export interface LoginProviderProps {
    children: ReactNode;
}

const initialContextValue: HeaderContextValue = {
    isMobileMenuOpen: false,
    setIsMobileMenuOpen: () => {},
};

export const HeaderContext =
    createContext<HeaderContextValue>(initialContextValue);

export function useHeader() {
    const context = useContext(HeaderContext);
    if (!context) {
        throw new Error('No Header Context initialised.');
    }
    return context;
}

export function HeaderProvider({ children }: LoginProviderProps) {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    return (
        <HeaderContext.Provider
            value={{ isMobileMenuOpen, setIsMobileMenuOpen }}
        >
            {children}
        </HeaderContext.Provider>
    );
}
