import { TourV2State } from '@/redux/TourV2State';
import {
    RequestTourAction,
    RequestTourSuccessAction,
    RequestTourFailureAction,
} from '@/redux/actions/tour';

import * as TYPES from '../actions/types';
import { Action } from '@/redux/actions/Action';
import { Tour as EnduranceTour } from '@/endurance/tours';
import { Tour } from '@/core';
import { parseISO } from 'date-fns';

const initialState = {
    loading: {},
    tours: {},
    errors: {},
};

function requestTour(
    state: TourV2State,
    action: RequestTourAction
): TourV2State {
    return {
        ...state,
        errors: {},
        loading: { ...state.loading, [action.tourId]: true },
    };
}

function requestTourSuccess(
    state: TourV2State,
    action: RequestTourSuccessAction
): TourV2State {
    return {
        ...state,
        loading: { ...state.loading, [action.tour.tourId]: false },
        tours: {
            ...state.tours,
            [action.tour.tourId]: transformTour(action.tour),
        },
        errors: { ...state.errors, [action.tour.tourId]: undefined },
    };
}

const requestTourFailure = (
    state: TourV2State,
    action: RequestTourFailureAction
): TourV2State => ({
    ...state,
    loading: { ...state.loading, [action.tourId]: false },
    tours: { ...state.tours, [action.tourId]: undefined },
    errors: { ...state.errors, [action.tourId]: action.errors },
});

const tour = (
    state: TourV2State = initialState,
    action: Action
): TourV2State => {
    const handlers: {
        [key: string]: (s: TourV2State, action?: Action) => TourV2State;
    } = {
        [TYPES.REQUEST_TOUR]: requestTour,
        [TYPES.REQUEST_TOUR_SUCCESS]: requestTourSuccess,
        [TYPES.REQUEST_TOUR_FAILURE]: requestTourFailure,
    };
    const handler: (site: TourV2State, action?: Action) => TourV2State =
        handlers[action.type];

    return handler ? handler(state, action) : state;
};

function transformTour(tour: EnduranceTour): Tour {
    return {
        accommodation: tour.accommodation,
        alternativeTours: tour.alternativeTours
            ? tour.alternativeTours.map(t => ({
                  tourId: t.tourId,
                  name: t.tourName,
              }))
            : [],
        balanceDueDays: tour.balanceDueDays,
        breakfastCount: tour.breakfasts,
        brochureLink: tour.brochureLink,
        countries: tour.countries.map(c => ({
            code: c.code,
            name: c.name,
        })),
        departures: tour.active
            ? tour.departures.map(d => ({
                  tourId: tour.tourId,
                  departureId: d.departureId,
                  startDate: parseISO(d.startDate),
                  endDate: parseISO(d.endDate),
                  spaces: d.spaces,
                  discountPercent: d.discountPercent,
                  isGuaranteedDeparture: d.guaranteedDeparture,
                  balanceDueDate: parseISO(d.balanceDue),
                  currencyCode: tour.departures[0].adultPrice.currencyCode,
                  sellingPrice: d.adultPrice,
                  sellingPricePerDay: d.adultPricePerDay,
                  originalPrice: d.originalPrice,
                  discount: d.discount,
                  deposit: d.deposit,
                  leadInTourDepartureFlightSellingPrice:
                      d.leadInTourDepartureFlightSellingPrice,
                  leadInTourDepartureFlightId: d.leadInTourDepartureFlightId,
                  leadInTourDepartureFlightDepartureDate: tour.leadInDeparture
                      ?.leadInTourDepartureFlightDepartureDate
                      ? parseISO(
                            tour.leadInDeparture
                                ?.leadInTourDepartureFlightDepartureDate
                        )
                      : null,
                  leadInTourDepartureFlightReturnDate: tour.leadInDeparture
                      ?.leadInTourDepartureFlightReturnDate
                      ? parseISO(
                            tour.leadInDeparture
                                ?.leadInTourDepartureFlightReturnDate
                        )
                      : null,
              }))
            : null,
        description: tour.description,
        dinnerCount: tour.dinners,
        duration: tour.duration,
        faqs: tour.faqs.map(({ question, answer }) => ({
            question,
            answer,
        })),
        femaleTravellerPercent: tour.femaleTravellerPercent,
        finishCity: tour.finishCity,
        groupedAttributes: tour.groupedAttributes.map(a => ({
            attribute: a.attribute,
            group: a.attributeGroup,
            showOnTourPage: a.showOnTourPage,
            description: a.description,
            isPrimary: a.isPrimary,
        })),
        guideStyle: tour.guideStyle,
        hasInsurance: tour.hasInsurance,
        hasPackagesAvailable: tour.hasPackagesAvailable,
        hasVisas: tour.hasVisas,
        highlights: tour.highlights,
        images: tour.images
            .filter(i => i.mediaType === 'Image')
            .map(i => ({
                type: 'Tap',
                source: i.source,
                order: i.order,
            })),
        importantInformation: tour.importantInformation.map(p => ({
            title: p.title,
            description: p.description,
        })),
        isActive: tour.active,
        isLocalSupplier: tour.isLocalOperator,
        itinerary: tour.itinerary.map(i => ({
            day: i.day,
            title: i.title,
            description: i.description,
            locations: i.locations.map(l => ({
                latitude: l.latitude,
                longitude: l.longitude,
            })),
            longitude: i.locations.length > 0 ? i.locations[0].longitude : null,
            latitude: i.locations.length > 0 ? i.locations[0].latitude : null,
            image:
                i.mainImage !== null
                    ? {
                          type: 'Tap',
                          source: i.mainImage.source,
                          order: i.mainImage.order,
                      }
                    : null,
            startLocation: null,
            endLocation: null,
            activities: i.activities,
            ascent: i.ascent,
            distance: i.distance,
            duration: i.duration,
            includesBreakfast: i.breakfastIncluded,
            includesLunch: i.lunchIncluded,
            includesDinner: i.dinnerIncluded,
        })),
        leadInDeparture:
            tour.active && tour.leadInDeparture
                ? {
                      tourId: tour.tourId,
                      departureId: tour.leadInDeparture?.departureId,
                      startDate: parseISO(tour.leadInDeparture?.startDate),
                      endDate: parseISO(tour.leadInDeparture?.endDate),
                      spaces: tour.leadInDeparture?.spaces,
                      discountPercent: tour.leadInDeparture?.discountPercent,
                      isGuaranteedDeparture:
                          tour.leadInDeparture?.guaranteedDeparture,
                      balanceDueDate: parseISO(
                          tour.leadInDeparture?.balanceDue
                      ),
                      currencyCode:
                          tour.leadInDeparture?.adultPrice?.currencyCode,
                      sellingPrice: tour.leadInDeparture?.adultPrice,
                      sellingPricePerDay:
                          tour.leadInDeparture?.adultPricePerDay,
                      originalPrice: tour.leadInDeparture?.originalPrice,
                      discount: tour.leadInDeparture?.discount,
                      deposit: tour.leadInDeparture?.deposit,
                      leadInTourDepartureFlightSellingPrice:
                          tour.leadInDeparture
                              ?.leadInTourDepartureFlightSellingPrice,
                      leadInTourDepartureFlightId:
                          tour.leadInDeparture?.leadInTourDepartureFlightId,
                      leadInTourDepartureFlightDepartureDate: tour
                          .leadInDeparture
                          ?.leadInTourDepartureFlightDepartureDate
                          ? parseISO(
                                tour.leadInDeparture
                                    ?.leadInTourDepartureFlightDepartureDate
                            )
                          : null,
                      leadInTourDepartureFlightReturnDate: tour.leadInDeparture
                          ?.leadInTourDepartureFlightReturnDate
                          ? parseISO(
                                tour.leadInDeparture
                                    ?.leadInTourDepartureFlightReturnDate
                            )
                          : null,
                  }
                : null,
        lunchCount: tour.lunches,
        maleTravellerPercent: tour.maleTravellerPercent,
        map: tour.map
            ? { type: 'Tap', source: tour.map.source, order: null }
            : null,
        maxAge: tour.maxAge,
        maxAverageAge: tour.maxAverageAge,
        maxGroupSize: tour.maxGroupSize,
        minAge: tour.minAge,
        minAverageAge: tour.minAverageAge,
        minGroupSize: tour.minGroupSize,
        name: tour.name,
        nationalities: tour.nationalities.map(n => ({
            nationality: n.nationality,
            percentage: n.percentage,
        })),
        operatorDisplayName: tour.operatorDisplayName,
        packingList: tour.packingList.map(p => ({
            title: p.title,
            description: p.description,
        })),
        physicalRating: tour.physicalRating,
        primaryAttribute: tour.primaryAttribute,
        primaryCountry: {
            code: tour.countryCode,
            name: tour.countryName,
        },
        region: {
            code: tour.regionCode,
            name: tour.regionName,
        },
        requiresVaccination: tour.requiresVaccination,
        reviewCount: tour.reviewCount,
        reviews: tour.reviews.map(r => ({
            author: r.author || 'Anonymous',
            reviewedOn: r.reviewedOn ? parseISO(r.reviewedOn) : null,
            rating: r.rating,
            title: r.title,
            text: r.text,
        })),
        reviewScore: tour.reviewScore,
        reviewSummary: null,
        startCity: tour.startCity,
        supplier: tour.operator,
        supplierId: tour.operatorId,
        supplierSlug: tour.operatorSlug,
        tourId: tour.tourId,
        transfers: tour.transfers,
    };
}

export default tour;
