import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import SalesHeader from './Components/SalesHeader/SalesHeader';
import Header from './Components/Header/Header';
import FooterHoc from './Components/Footer/FooterHoc';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import { Routes } from './routes';
import BusinessStructuredData from './Components/StructuredData/BusinessStructuredData/BusinessStructuredData';
import WebsiteStructuredData from './Components/StructuredData/WebsiteStructuredData/WebsiteStructuredData';
import LiveChat from './Components/LiveChat/LiveChat';
import CookieBanner from './Components/Cookies/CookieBanner/CookieBanner';
import { CookieAcceptanceProvider } from './contexts/CookieAcceptanceContext';
import { FeatureFlagProvider } from './contexts/FeatureFlagContext';
import { LoginProvider } from './contexts/LoginContext';
import { ExperimentTrackingProvider } from './contexts/ExperimentTrackingContext';

import 'normalize.css';
import './styles/main.scss';
import './App.scss';
import 'react-day-picker/dist/style.css';
import { HeaderProvider } from './Components/Header/Header.v2/HeaderContext.v2';

if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
}

const App = () => (
    <Router>
        <ErrorBoundary>
            <BusinessStructuredData />
            <WebsiteStructuredData />
            <ExperimentTrackingProvider>
                <CookieAcceptanceProvider>
                    <LoginProvider>
                        <FeatureFlagProvider>
                            <HeaderProvider>
                                <SalesHeader />
                                <Header />
                                <main className="main">
                                    <Routes />
                                </main>
                                <FooterHoc />
                                <LiveChat />
                                <CookieBanner />
                            </HeaderProvider>
                        </FeatureFlagProvider>
                    </LoginProvider>
                </CookieAcceptanceProvider>
            </ExperimentTrackingProvider>
        </ErrorBoundary>
    </Router>
);

export default App;
