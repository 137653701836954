import { combineReducers } from 'redux';

import accommodation from './accommodation.reducer';
import accommodationRooms from './accommodationRooms.reducer';
import accommodationSearch from './accommodationSearch.reducer';

import agents from './agents.reducer';
import packages from './packages.reducer';
import packagesSearch from './packagesSearch.reducer';
import checkout from './checkout.reducer';
import favourites from './favourites.reducer';
import flights from './flights.reducer';

import tourSearch from './tourSearch.reducer';
import tourFlightSearch from './tourFlightSearch.reducer';
import tourPackages from './tourPackages.reducer';
import site from './site.reducer';
import tour from './tour.reducer';
import tourV2 from './tour.v2.reducer';
import lookups from './lookups.reducer';
import customers from './customers.reducer';
import departures from './tourDeparture.reducer';
import tourDepartureFlights from './tourDepartureFlight.reducer';

const rootReducer = combineReducers({
    accommodation,
    accommodationRooms,
    accommodationSearch,
    agents,
    packages,
    packagesSearch,
    checkout,
    favourites,
    flights,
    site,
    tour,
    tourV2,
    tourSearch,
    tourFlightSearch,
    tourPackages,
    lookups,
    customers,
    departures,
    tourDepartureFlights,
});

export default rootReducer;
