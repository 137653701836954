import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Icon from '../../../common/Icon/Icon';
import { toggleMobileSearchMenu } from '../../../../redux/actions/site.actions';
import styles from './MobileSearchMenuButton.module.scss';

function MobileSearchMenuButton({
    isMobileSearchMenuActive,
    toggleMobileSearchMenu,
    className,
    ...props
}) {
    return (
        <button
            className={classNames({
                [styles.mobileSearchMenuButton]: true,
                [styles.isActive]: isMobileSearchMenuActive,
                [className]: !!className,
            })}
            onClick={toggleMobileSearchMenu}
            type="button"
            aria-label="Toggle search"
            {...props}
        >
            <Icon
                className={styles.icon}
                icon="search"
                height={null}
                width={null}
            />
        </button>
    );
}

MobileSearchMenuButton.propTypes = {
    isMobileSearchMenuActive: PropTypes.bool.isRequired,
    toggleMobileSearchMenu: PropTypes.func.isRequired,
};

const mapStateToProps = ({ site }) => ({
    isMobileSearchMenuActive: site.isMobileSearchMenuActive,
});

function mapDispatchToProps(dispatch) {
    return {
        toggleMobileSearchMenu: params =>
            dispatch(toggleMobileSearchMenu(params)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileSearchMenuButton);
