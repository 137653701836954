import classNames from 'classnames';
import styles from './DesktopLinks.module.scss';
import DesktopLink from './DesktopLink/DesktopLink';

export interface DesktopLinksProps {
    className?: string;
}

function DesktopLinks({ className }: DesktopLinksProps) {
    return (
        <ul className={classNames(styles.desktopLinks, className)}>
            <DesktopLink className={styles.link}>Deals</DesktopLink>
            <DesktopLink className={styles.link}>Destinations</DesktopLink>
            <DesktopLink className={styles.link}>Adventures</DesktopLink>
        </ul>
    );
}

export default DesktopLinks;
