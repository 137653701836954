import { SuggestionType } from './SuggestionType';
import { Suggestion } from './Suggestion';

export const destinations: Suggestion[] = [
    {
        name: 'Great Barrier Reef',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    {
        name: 'Grand Canyon',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    {
        name: 'Yellowstone national park',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    { name: 'Dubai', type: SuggestionType.Destination, isPopular: false },
    { name: 'Silk Road', type: SuggestionType.Destination, isPopular: false },
    {
        name: 'The Galapagos',
        type: SuggestionType.Destination,
        isPopular: true,
    },
    { name: 'Giza ', type: SuggestionType.Destination, isPopular: false },
    {
        name: 'Victoria Falls',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    { name: 'Angel Falls', type: SuggestionType.Destination, isPopular: false },
    {
        name: 'Death Valley',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    {
        name: 'Amazon Rainforest',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    { name: 'Cappadocia', type: SuggestionType.Destination, isPopular: false },
    { name: 'Ha Long Bay', type: SuggestionType.Destination, isPopular: false },
    {
        name: 'Namib Desert',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    {
        name: 'Plitvice Lakes National Park',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    {
        name: 'Milford Sound',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    {
        name: 'The Golden Triangle',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    {
        name: 'Lake Titicaca',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    {
        name: 'The Lake District',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    { name: 'Kyoto', type: SuggestionType.Destination, isPopular: false },
    {
        name: 'Easter Island',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    {
        name: 'Ngorongoro Crater',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    { name: 'Venice', type: SuggestionType.Destination, isPopular: false },
    { name: 'Patagonia', type: SuggestionType.Destination, isPopular: true },
    { name: 'Lake Louise', type: SuggestionType.Destination, isPopular: false },
    { name: 'Marrakech', type: SuggestionType.Destination, isPopular: false },
    { name: 'Samarkand', type: SuggestionType.Destination, isPopular: false },
    {
        name: 'Amalfi Coast',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    { name: 'Masai mara', type: SuggestionType.Destination, isPopular: false },
    {
        name: 'Camino de Santiago',
        type: SuggestionType.Destination,
        isPopular: false,
    },
    { name: 'Serengeti', type: SuggestionType.Destination, isPopular: false },
];
