import classNames from 'classnames';
import styles from './DesktopCurrencySelector.module.scss';

export interface DesktopCurrencySelectorProps {
    className?: string;
}

function DesktopCurrencySelector({ className }: DesktopCurrencySelectorProps) {
    return (
        <div
            className={classNames(styles.desktopCurrencySelector, className)}
        ></div>
    );
}

export default DesktopCurrencySelector;
