import classNames from 'classnames';
import Button, { ButtonProps } from '../Button/Button';
import Icon, { Icons } from '../../Icon/Icon';
import styles from './IconButton.module.scss';

export type IconButtonProps = {
    icon: keyof Icons;
    iconColor?: string;
    iconHeight?: number;
    iconWidth?: number;
} & ButtonProps;

const IconButton = ({
    children,
    icon,
    iconColor = '406365',
    iconHeight = 20,
    iconWidth = 20,
    className,
    ...props
}: IconButtonProps) => {
    return (
        <Button className={classNames(styles.iconButton, className)} {...props}>
            {children}{' '}
            <Icon
                icon={icon}
                color={iconColor}
                height={iconHeight}
                width={iconWidth}
            />
        </Button>
    );
};

export default IconButton;
