import { GetParams } from '@/apis/tourPackagesApi';
import { TourPackage } from '@/endurance/tourPackages';
import { AirportValue } from '../Components/common/AirportSelectAsync/AirportValue';

import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { selectIsPackageNotFound } from '../redux/selectors/tourPackages.selectors';
import { selectSelectedCurrencyCode } from '../redux/selectors/site.selectors';
import {
    getPackageParamsFromQuery,
    getQueryFromParams,
} from '../services/tourPackageService';
import { getPackageRequest } from '../redux/actions/tourPackages.actions';
import { getSearchParamsFromQuery } from '../services/tourFlightSearchService';
import { TourFlightSearchParams } from '@/core/tourFlightSearch';
import { useFlights } from '../hooks/useFlights';
import {
    newFlightsCheckoutSession,
    setPackageSelected,
} from '../redux/actions';
import { logErrorMessage } from '../utils/sentry';

export interface TourPackageContextValue {
    isLoading: boolean;
    isNotFound: boolean;
    tourPackage: TourPackage | null;
    departureAirport: AirportValue | null;
    travellerCount: number;
    onDepartureAirportChange: (value: AirportValue) => void;
    onTravellerCountChange: (count: number) => void;
    onSelectPackage: (selectedPackage: TourPackage) => void;
}

export interface TourPackageContextProps {
    children: ReactNode;
}

const TourPackageContext = createContext<TourPackageContextValue | null>(null);

export function useTourPackage() {
    const context = useContext(TourPackageContext);
    if (!context) {
        throw new Error('No Tour Package Context initialised.');
    }
    return context;
}

export function TourPackageProvider({ children }: TourPackageContextProps) {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { search, pathname } = useLocation();
    const isLoading = useAppSelector(state => state.tourPackages.loading);
    const tourPackage = useAppSelector(state => state.tourPackages.package);
    const isNotFound = useAppSelector(state =>
        selectIsPackageNotFound(state.tourPackages)
    );
    const selectedCurrency = useAppSelector(state =>
        selectSelectedCurrencyCode(state)
    );
    const [departureAirport, setDepartureAirport] =
        useState<AirportValue | null>(null);
    const {
        isLoading: flightsIsLoading,
        airports,
        airportGroups,
    } = useFlights();
    const [travellerCount, setTravellerCount] = useState(1);
    const [packageParams, setPackageParams] = useState<GetParams | null>(null);
    const [flightSearchParams, setFlightSearchParams] =
        useState<TourFlightSearchParams | null>(null);
    const [tourId, setTourId] = useState<string | null>(null);
    const [departureId, setDepartureId] = useState<number | null>(null);

    useEffect(() => {
        const packageParams: GetParams = getPackageParamsFromQuery(search);
        setPackageParams(packageParams);
        setTourId(packageParams.tourId);
        setDepartureId(packageParams.departureId);
        const flightSearchParams: TourFlightSearchParams =
            getSearchParamsFromQuery(search);
        setFlightSearchParams(flightSearchParams);
    }, [search]);

    useEffect(() => {
        if (isLoading === null && packageParams !== null) {
            dispatch(getPackageRequest(packageParams, selectedCurrency));
        }
    }, [dispatch, isLoading, packageParams, selectedCurrency]);

    useEffect(() => {
        if (flightSearchParams !== null) {
            let departureAirport: AirportValue | null = null;
            if (flightSearchParams.departureAirportCode) {
                const airport = airports.find(
                    airport =>
                        airport.code === flightSearchParams.departureAirportCode
                );
                if (airport) {
                    departureAirport = {
                        name: airport.name,
                        code: airport.code,
                        countryName: '',
                        type: 'Airport',
                    };
                }
            } else if (flightSearchParams.departureAirportGroupCode) {
                const airportGroup = airportGroups.find(
                    group =>
                        group.code ===
                        flightSearchParams.departureAirportGroupCode
                );
                if (airportGroup) {
                    departureAirport = {
                        name: airportGroup.name,
                        code: airportGroup.code,
                        countryName: '',
                        type: 'AirportGroup',
                    };
                }
            }

            setDepartureAirport(departureAirport);

            if (flightSearchParams.adults) {
                setTravellerCount(flightSearchParams.adults);
            }
        }
    }, [flightSearchParams, airports, airportGroups]);

    return (
        <TourPackageContext.Provider
            value={{
                isLoading: (isLoading ?? true) || flightsIsLoading,
                isNotFound,
                tourPackage,
                departureAirport,
                travellerCount,
                onDepartureAirportChange,
                onTravellerCountChange,
                onSelectPackage: handleSelectPackage,
            }}
        >
            {children}
        </TourPackageContext.Provider>
    );

    function onDepartureAirportChange(value: AirportValue) {
        if (flightSearchParams !== null && packageParams !== null) {
            const newParams = { ...flightSearchParams };
            if (value.type === 'Airport') {
                newParams.departureAirportCode = value.code;
                newParams.departureAirportGroupCode = null;
            } else if (value.type === 'AirportGroup') {
                newParams.departureAirportGroupCode = value.code;
                newParams.departureAirportCode = null;
            }
            updateQuery(packageParams, newParams);
        }
    }

    function onTravellerCountChange(count: number) {
        if (flightSearchParams !== null && packageParams !== null) {
            const newFlightSearchParams = { ...flightSearchParams };
            const newPackageSearchParams = { ...packageParams };
            newFlightSearchParams.adults = count;
            newPackageSearchParams.adults = count;
            updateQuery(newPackageSearchParams, newFlightSearchParams);
        }
    }
    function updateQuery(
        packageParams: GetParams,
        flightSearchParams: TourFlightSearchParams
    ) {
        const query = getQueryFromParams(packageParams, flightSearchParams);
        history.replace(`${pathname}?${query}`);
    }

    function handleSelectPackage(selectedPackage: TourPackage) {
        if (!tourId || !departureId) {
            dispatch(newFlightsCheckoutSession(tourId, departureId));

            const paymentType = 'full';

            dispatch(
                setPackageSelected(
                    selectedPackage,
                    travellerCount,
                    paymentType,
                    selectedPackage.prices
                )
            );
            // TODO: Handle if we ever build packages
            // history.push(urlService.getCheckoutPaymentUrl(tourId, departureId));
        } else {
            logErrorMessage(
                'Failed to select booking as missing tourId or departureId.'
            );
        }
    }
}
