import { tapApiBaseUrl, tapApiAuthKey, cosmicSiteSlug } from '../utils/config';
import { parseISO } from 'date-fns';

import { BasicApiResponse } from '@/endurance';
import {
    ActivitiesPageDataResult,
    ActivityPageDataResult,
    DealsPageDataResult,
    DestinationPageDataResult,
    DestinationsPageDataResult,
    AllDestinationsPageDataResult,
    FaqsPageDataResult,
    HomepageDataResult,
    PageDataResult,
    SiteDataResult,
    DestinationCategoryPageDataResult,
    ActivityCategoryPageDataResult,
} from '@/endurance/content';

import {
    ActivitiesPageData,
    ActivityCategoryPageData,
    ActivityPageData,
    ContentResponse,
    ContentApi,
    DealPageData,
    DealsPageData,
    DestinationCategoryPageData,
    DestinationPageData,
    DestinationsPageData,
    DestinationsPageDestination,
    FaqsPageData,
    HomePageData,
    PageData,
    SiteData,
    TradeSignupPageData,
} from '@/apis/contentApi';
import { TradeSignupPageDataResult } from '@/endurance/content/TradeSignupPageDataResult';

const contentBaseUrl = `${tapApiBaseUrl}/content`;

async function cosmicRequest<TResult>(
    query: string
): Promise<ContentResponse<TResult>> {
    const url: string = `${contentBaseUrl}/${query}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-AuthKey': tapApiAuthKey,
        },
    })
        .then(response => response.json() as Promise<BasicApiResponse<TResult>>)
        .then(response => ({
            result: response.result,
            success: response.success,
            errors: response.errors ?? [],
        }));
}

async function getAboutUsPage(): Promise<PageData> {
    return await cosmicRequest<PageDataResult>('pages/about-us').then(
        ({ result, errors }) => {
            if (errors.length > 0) {
                throw new Error(`Get About Us Page Request Error: ${errors}`);
            } else if (!result) {
                throw new Error('About Us Page Not Found');
            }

            const data: PageData = {
                title: result.title,
                slug: result.slug,
                meta: {
                    title: result.metaTitle,
                    description: result.metaDescription,
                },
                content: result.content,
                otherContent: result.otherContent.map(content => ({
                    title: content.title,
                    content: content.content,
                })),
                heading: result.heading,
                subHeading: result.subHeading,
                mainImage: result.mainImage,
                videos: result.videos.map((video: any) => ({
                    type: video.type,
                    url: video.url,
                })),
                questions: [],
            };
            return data;
        }
    );
}

async function getTailorMadePage(): Promise<PageData> {
    return await cosmicRequest<PageDataResult>('pages/tailor-made').then(
        ({ result, errors }) => {
            if (errors.length > 0) {
                throw new Error(
                    `Get Tailor Made Page Request Error: ${errors}`
                );
            } else if (!result) {
                throw new Error('Tailor Made Not Found');
            }

            const data: PageData = {
                title: result.title,
                slug: result.slug,
                meta: {
                    title: result.metaTitle,
                    description: result.metaDescription,
                },
                content: result.content,
                otherContent: result.otherContent.map(content => ({
                    title: content.title,
                    content: content.content,
                })),
                heading: result.heading,
                subHeading: result.subHeading,
                mainImage: result.mainImage,
                videos: result.videos.map((video: any) => ({
                    type: video.type,
                    url: video.url,
                })),
                questions: [],
            };

            return data;
        }
    );
}

async function getSiteData(): Promise<SiteData> {
    return await cosmicRequest<SiteDataResult>(
        `siteData?slug=${cosmicSiteSlug}`
    ).then(({ result, errors }) => {
        if (errors.length > 0) {
            throw new Error(`Get Site Data Request Error: ${errors}`);
        } else if (!result) {
            throw new Error('Site Data Not Found');
        }

        const returnData: SiteData = {
            header: {
                dealsMenuTitle: result.dealsMenuTitle,
                dealsMenuUrl: result.dealsMenuUrl,
                dealsLinks: result.dealsLinks.map(({ title, slug }) => ({
                    title,
                    slug,
                })),
            },
            saleBanner: result.saleBannerTitle
                ? {
                      title: result.saleBannerTitle,
                      imageUrl: result.saleBannerImageUrl,
                      linkText: result.saleBannerLinkText,
                      linkUrl: result.saleBannerLinkUrl,
                  }
                : null,
            reviews: result.reviews.map(review => ({
                title: review.title,
                rating: review.rating,
                text: review.text,
                author: review.author,
                date: parseISO(review.date),
            })),
            regions: result.regions.map(region => ({
                title: region.title,
                slug: region.slug,
                destinations: region.destinations.map(destination => ({
                    title: destination.title,
                    slug: destination.slug,
                    isPopular: destination.isPopular,
                    hideOnDestinationPage: destination.hideOnDestinationPage,
                })),
            })),
            activities: result.activities.map(activity => ({
                title: activity.title,
                slug: activity.slug,
                type: activity.type,
                isPopular: activity.isPopular,
                isIconic: activity.isIconic,
                imageUrl: activity.imageUrl,
            })),
            currencies: result.currencies
                .filter(({ enabled }) => {
                    return enabled;
                })
                .map(currency => ({
                    code: currency.code,
                    symbol: currency.symbol,
                    position: currency.position,
                    space: currency.space,
                    name: currency.name,
                    flag: currency.flag,
                    displayTelephoneNumber: currency.displayTelephoneNumber,
                    telephoneNumber: currency.telephoneNumber,
                    telephoneNumberTailormade: null,
                    displayTailorMadeTelephoneNumber: null,
                    locale: currency.locale,
                })),
            tailorMadeImageUrl: result.tailorMadeImageUrl,
            agentHeroImageUrl: result.agentHeroImageUrl,
            agentImageUrls: result.agentImageUrls,
        };

        return returnData;
    });
}

async function getDealsPageBySlug(slug: string): Promise<DealsPageData> {
    return await cosmicRequest<DealsPageDataResult>(`deals/${slug}`).then(
        ({ result, errors }) => {
            if (errors.length > 0) {
                throw new Error(`Get Deals Page Request Error: ${errors}`);
            } else if (!result) {
                throw new Error('Deals Page Not Found');
            }

            const pageData: DealsPageDataResult = result;
            return {
                title: pageData.title,
                slug: pageData.slug,
                meta: {
                    title: pageData.metaTitle,
                    description: pageData.metaDescription,
                },
                content: pageData.content,
                heading: pageData.heading,
                subHeading: pageData.subHeading,
                mainImage: pageData.mainImage,
                deals: pageData.dealsLinks
                    ? pageData.dealsLinks
                          .filter(deal => !!deal)
                          .map(deal => ({
                              title: deal.title,
                              url: `/deals/${deal.slug}`,
                              imageUrl: deal.imageUrl,
                          }))
                    : [],
                videos: [],
                questions: [],
                otherContent: [],
            };
        }
    );
}

async function getHomepage(): Promise<HomePageData> {
    return await cosmicRequest<HomepageDataResult>(`home`).then(
        ({ result, errors }) => {
            if (errors.length > 0) {
                throw new Error(`Get Home Page Request Error: ${errors}`);
            } else if (!result) {
                throw new Error('Home Page Not Found');
            }
            return {
                heading: result.heading,
                subHeading: result.subHeading,
                meta: {
                    title: result.metaTitle,
                    description: result.metaDescription,
                },
                mainImage: result.mainImage,
                videos: [],
                emailSignupText: result.emailSignupText,
                offersTitle: result.offersTitle,
                offers: result.offers.map(offer => ({
                    title: offer.title,
                    url: offer.url,
                    imageUrl: offer.imageUrl,
                })),
                blogCategories: result.blogCategories,
                questions: result.questions.map((question: any) => ({
                    question: question.question,
                    answer: question.answer,
                })),
                title: 'The Adventure People',
                slug: '',
                content: '',
                otherContent: [],
                links: {
                    title: result.linkTitle ?? 'Popular Adventures',
                    carousels: result.linkCarousels.map(c => ({
                        title: c.title,
                        links: c.links.map(l => ({
                            title: l.title,
                            url: l.url,
                            image: {
                                source: l.image,
                                type: 'Cosmic',
                                order: null,
                            },
                        })),
                    })),
                },
                tailorMadeImageUrl: result.tailorMadeImageUrl,
                topLinks: result.topLinks.map(link => ({
                    text: link.text,
                    url: link.url,
                    imageUrl: link.imageUrl,
                    buttonText: link.buttonText,
                })),
                agentHeroImageUrl: result.agentHeroImageUrl,
                agentImageUrls: result.agentImageUrls,
            };
        }
    );
}

async function getTradeSignupPage(): Promise<TradeSignupPageData> {
    return await cosmicRequest<TradeSignupPageDataResult>(`tradeSignup`).then(
        ({ result, errors }) => {
            if (errors.length > 0) {
                throw new Error(
                    `Get Trade Signup Page Request Error: ${errors}`
                );
            } else if (!result) {
                throw new Error('Trade Signup Page Not Found');
            }
            return {
                joinOurCommunityImage: result.joinOurCommunityImage,
                whyJoinUsPoints: result.whyJoinUsPoints.map(w => ({
                    heading: w.heading,
                    text: w.text,
                })),
                howDoesItWorkSteps: result.howDoesItWorkSteps.map(h => ({
                    title: h.title,
                    description: h.description,
                })),
                howDoesItWorkImage: result.howDoesItWorkImage,
                whatOurPartnersSayImage: result.whatOurPartnersSayImage,
                partnerReviewRichText: result.partnerReviewRichText,
                imagePartnerReviewRichText: result.imagePartnerReviewRichText,
                metaTitle: result.metaTitle,
                metaDescription: result.metaDescription,
            };
        }
    );
}

async function getDealPageBySlug(slug: string): Promise<DealPageData> {
    const dealsResult = await cosmicRequest<PageDataResult>(
        `deal/${slug}`
    ).then(({ result, errors }) => {
        if (errors.length > 0) {
            throw new Error(`Get Deal Page Request Error: ${errors}`);
        } else if (!result) {
            throw new Error('Deal Page Not Found');
        }
        return result;
    });

    const homePageResult = await getHomepage();

    const deal = dealsResult;
    const homePage = homePageResult;

    const data: DealPageData = {
        title: deal.title,
        slug: deal.slug,
        meta: {
            title: deal.metaTitle,
            description: deal.metaDescription,
        },
        content: deal.content,
        heading: deal.heading,
        subHeading: deal.subHeading,
        mainImage: deal.mainImage,
        otherContent: [],
        videos: [],
        questions: [],
        blogCategories: homePage.blogCategories.map(wordpress_category_id =>
            Number(wordpress_category_id)
        ),
    };

    return data;
}

async function getActivitiesPage(
    slug: string = 'all-activities'
): Promise<ActivitiesPageData> {
    return await cosmicRequest<ActivitiesPageDataResult>(
        `activities/${slug}`
    ).then(({ result, errors }) => {
        if (errors.length > 0) {
            throw new Error(`Get Activities Page Request Error: ${errors}`);
        } else if (!result) {
            throw new Error('Activities Not Found');
        }

        const data = result;
        return {
            title: data.title,
            slug: data.slug,
            meta: {
                title: data.metaTitle,
                description: data.metaDescription,
            },
            mainImage: data.mainImage,
            heading: data.heading,
            subHeading: data.subHeading,
            activities: data.activities.map(activity => ({
                title: activity.title,
                slug: activity.slug,
                imageUrl: activity.mainImage,
                isIconic: activity.isIconic,
                isPopular: activity.isPopular,
            })),
            content: '',
            questions: [],
            otherContent: [],
            videos: [],
        };
    });
}

async function getActivityPageBySlug(slug: string): Promise<ActivityPageData> {
    return await cosmicRequest<ActivityPageDataResult>(`activity/${slug}`).then(
        ({ result, errors }) => {
            if (errors.length > 0) {
                throw new Error(`Get Activity Page Request Error: ${errors}`);
            } else if (!result) {
                throw new Error('Activity Not Found');
            }

            const data: ActivityPageData = {
                title: result.title,
                slug: result.slug,
                meta: {
                    title: result.metaTitle,
                    description: result.metaDescription,
                },
                content: result.content,
                otherContent: result.otherContent
                    ? result.otherContent.map(content => ({
                          title: content.title,
                          content: content.content,
                      }))
                    : [],
                heading: result.heading,
                subHeading: result.subHeading,
                heroButtonText: result.heroButtonText,
                mainImage: result.mainImage,
                otherImages: result.otherImages ? result.otherImages : [],
                videos: [],
                relatedTours: [],
                searchParams: result.searchParams,
                highlights: result.highlights
                    ? result.highlights.map(highlight => ({
                          text: highlight.title,
                          url: highlight.url,
                          imageUrl: highlight.imageUrl,
                      }))
                    : [],
                activityCategories: result.activityCategories
                    ? result.activityCategories.map(category => ({
                          title: category.title,
                          url: `/activities/${result.slug}/${category.url}`,
                          imageUrl: category.imageUrl,
                      }))
                    : [],
                activityLinks: result.activityLinks
                    ? result.activityLinks.map(link => ({
                          title: link.title,
                          url: link.url,
                          imageUrl: link.imageUrl,
                      }))
                    : [],
                questions: [],
            };
            return data;
        }
    );
}

async function getActivityCategoryPageBySlug(
    slugActivityCategory: string
): Promise<ActivityCategoryPageData> {
    return await cosmicRequest<ActivityCategoryPageDataResult>(
        `activityCategory/${slugActivityCategory}`
    ).then(({ result, errors }) => {
        if (errors.length > 0) {
            throw new Error('Get Activity Category Page Request Error');
        } else if (!result) {
            throw new Error('Activity Category Not Found');
        }

        const data: ActivityCategoryPageData = {
            title: result.title,
            slug: result.slug,
            meta: {
                title: result.metaTitle,
                description: result.metaDescription,
            },
            content: result.content,
            otherContent: result.otherContent
                ? result.otherContent.map(content => ({
                      title: content.title,
                      content: content.content,
                  }))
                : [],
            heading: result.heading,
            subHeading: result.subHeading,
            heroButtonText: result.heroButtonText,
            mainImage: result.mainImage,
            videos: [],
            searchParams: result.searchParams,
            questions: [],
            activity: {
                name: result.activity.name,
                slug: result.activity.slug,
            },
        };
        return data;
    });
}

async function getFAQsPage(): Promise<FaqsPageData> {
    return await cosmicRequest<FaqsPageDataResult>('faqs/all-faqs').then(
        ({ result, errors }) => {
            if (errors.length > 0) {
                throw new Error(`Get FAQs Page Request Error: ${errors}`);
            } else if (!result) {
                throw new Error('FAQs Not Found');
            }

            const pageData = result;
            const data: FaqsPageData = {
                title: pageData.title,
                slug: pageData.slug,
                meta: {
                    title: pageData.metaTitle,
                    description: pageData.metaDescription,
                },
                mainImage: pageData.mainImage,
                heading: pageData.heading,
                subHeading: pageData.subHeading,
                content: '',
                otherContent: [],
                questions: [],
                videos: [],
                faqs: pageData.faqLinks.map(faq => ({
                    title: faq.title,
                    slug: faq.slug,
                    imageUrl: faq.imageUrl,
                })),
            };
            return data;
        }
    );
}

async function getDestinationPageBySlug(
    slug: string
): Promise<DestinationPageData> {
    return await cosmicRequest<DestinationPageDataResult>(
        `destination/${slug}`
    ).then(({ result, errors }) => {
        if (errors.length > 0) {
            throw new Error(`Get Destination Page Request Error: ${errors}`);
        } else if (!result) {
            throw new Error('Destination Not Found');
        }

        const data: DestinationPageData = {
            title: result.title,
            slug: result.slug,
            meta: {
                title: result.metaTitle,
                description: result.metaDescription,
            },
            content: result.content,
            otherContent: result.otherContent
                ? result.otherContent.map(content => ({
                      title: content.title,
                      content: content.content,
                  }))
                : [],
            heading: result.heading,
            subHeading: result.subHeading,
            heroButtonText: result.heroButtonText,
            region: result.region
                ? {
                      name: result.region.name,
                      slug: result.region.slug,
                  }
                : null,
            mainImage: result.mainImage,
            otherImages: result.otherImages ? result.otherImages : [],
            searchParams: result.searchParams,
            highlights: result.highlights
                ? result.highlights.map(highlight => ({
                      text: highlight.title,
                      url: highlight.url,
                      imageUrl: highlight.imageUrl,
                  }))
                : [],
            destinationCategories: result.destinationCategories
                ? result.destinationCategories.map(category => ({
                      title: category.title,
                      url: `/destinations/${result.slug}/${category.url}`,
                      imageUrl: category.imageUrl,
                  }))
                : [],
            destinationLinks: result.destinationLinks
                ? result.destinationLinks.map(link => ({
                      title: link.title,
                      url: link.url,
                      imageUrl: link.imageUrl,
                  }))
                : [],
            videos: [],
            questions: [],
        };

        return data;
    });
}

async function getDestinationCategoryPageBySlug(
    slug: string
): Promise<DestinationCategoryPageData> {
    return await cosmicRequest<DestinationCategoryPageDataResult>(
        `destinationCategory/${slug}`
    ).then(({ result, errors }) => {
        if (errors.length > 0) {
            throw new Error('Get Destination Category Page Request Error');
        } else if (!result) {
            throw new Error('Destination Category Not Found');
        }

        const destinationCategory = result;
        const destination = result;

        const data: DestinationCategoryPageData = {
            title: destinationCategory.title,
            slug: destinationCategory.slug,
            meta: {
                title: destinationCategory.metaTitle,
                description: destinationCategory.metaDescription,
            },
            content: destinationCategory.content,
            otherContent: destinationCategory.otherContent
                ? destinationCategory.otherContent.map(content => ({
                      title: content.title,
                      content: content.content,
                  }))
                : [],
            heading: destinationCategory.heading,
            subHeading: destinationCategory.subHeading,
            heroButtonText: destinationCategory.heroButtonText,
            mainImage: destinationCategory.mainImage,
            videos: [],
            searchParams: destinationCategory.searchParams,
            destination: {
                name: destination.destination.name,
                slug: destination.destination.slug,
            },
            questions: [],
        };

        return data;
    });
}

async function getAllDestinationsPage(): Promise<DestinationsPageData> {
    return await cosmicRequest<AllDestinationsPageDataResult>(
        'alldestinations'
    ).then(({ result, errors }) => {
        if (errors.length > 0) {
            throw new Error(
                `Get All Destination Page Request Error: ${errors}`
            );
        } else if (!result) {
            throw new Error('No Destinations Found');
        }

        const data: DestinationsPageData = {
            title: result.title,
            slug: result.slug,
            meta: {
                title: result.metaTitle,
                description: result.metaDescription,
            },
            mainImage: result.mainImage,
            heading: result.heading,
            subHeading: result.subHeading,
            content: '',
            otherContent: [],
            videos: [],
            questions: [],
            regions: result.destinationsLinks.map(destinationsLink => {
                return {
                    title: destinationsLink.title,
                    slug: destinationsLink.slug,
                    imageUrl: destinationsLink.imageUrl,
                    destinations: destinationsLink.destinationLinks.map(
                        destinationLink => ({
                            title: destinationLink.title,
                            slug: destinationLink.slug,
                            imageUrl: destinationLink.imageUrl,
                            regionSlug: destinationLink.regionSlug,
                            isPopular: false,
                        })
                    ),
                };
            }),
        };
        return data;
    });
}

async function getDestinationsPageBySlug(
    slug: string
): Promise<DestinationsPageData> {
    return await cosmicRequest<DestinationsPageDataResult>(
        `destinations/${slug}`
    ).then(({ result, errors }) => {
        if (errors.length > 0) {
            throw new Error(`Get Destinations Page Request Error: ${errors}`);
        } else if (!result) {
            throw new Error('Destinations Not Found');
        }

        const destinations: DestinationsPageDestination[] =
            result.destinations.map((destination: any) => ({
                title: destination.title,
                slug: destination.slug,
                imageUrl: destination.imageUrl,
                regionSlug: destination.regionSlug,
                isPopular: true,
            }));

        //Todo : this model is not quite correct but it's here to maintain backwards compatibility on the page
        //It should be replaced with a model without regions as they are only relevant for the All destinations page
        //Only popular destinations uses this request
        const data: DestinationsPageData = {
            title: result.title,
            slug: result.slug,
            meta: {
                title: result.metaTitle,
                description: result.metaDescription,
            },
            mainImage: result.mainImage,
            heading: result.heading,
            subHeading: result.subHeading,
            content: '',
            otherContent: [],
            questions: [],
            videos: [],
            regions: [
                {
                    title: '',
                    slug: '',
                    imageUrl: '',
                    destinations: destinations,
                },
            ],
        };

        return data;
    });
}

async function getFAQPageBySlug(slug: string): Promise<PageData> {
    return await cosmicRequest<PageDataResult>(`faq/${slug}`).then(
        ({ result, errors }) => {
            if (errors.length > 0) {
                throw new Error(`Get FAQ Page Request Error: ${errors}`);
            } else if (!result) {
                throw new Error('FAQ Not Found');
            }

            const pageData = result;
            const data: PageData = {
                title: pageData.title,
                slug: pageData.slug,
                meta: {
                    title: pageData.metaTitle,
                    description: pageData.metaDescription,
                },
                content: pageData.content,
                heading: pageData.heading,
                subHeading: pageData.subHeading,
                mainImage: pageData.mainImage,
                questions: pageData.questions
                    ? pageData.questions.map(question => ({
                          question: question.question,
                          answer: question.answer,
                      }))
                    : [],
                otherContent: [],
                videos: [],
            };

            return data;
        }
    );
}

async function getPageBySlug(slug: string): Promise<PageData> {
    return await cosmicRequest<PageDataResult>(`pages/${slug}`).then(
        ({ result, errors }) => {
            if (errors.length > 0) {
                throw new Error(`Get Page Request Error: ${errors}`);
            } else if (!result) {
                throw new Error('Page Not Found');
            }

            const data: PageData = {
                title: result.title,
                slug: result.slug,
                meta: {
                    title: result.metaTitle,
                    description: result.metaDescription,
                },
                content: result.content,
                heading: result.heading,
                subHeading: result.subHeading,
                mainImage: result.mainImage,
                otherContent: result.otherContent
                    ? result.otherContent.map(content => ({
                          title: content.title,
                          content: content.content,
                      }))
                    : [],
                videos: [],
                questions: result.questions
                    ? result.questions.map((content: any) => ({
                          question: content.question,
                          answer: content.answer,
                      }))
                    : [],
            };
            return data;
        }
    );
}

const contentApi: ContentApi = {
    getAboutUsPage,
    getActivitiesPage,
    getActivityPageBySlug,
    getActivityCategoryPageBySlug,
    getAllDestinationsPage,
    getDealPageBySlug,
    getDealsPageBySlug,
    getDestinationCategoryPageBySlug,
    getDestinationPageBySlug,
    getDestinationsPageBySlug,
    getFAQPageBySlug,
    getFAQsPage,
    getHomepage,
    getPageBySlug,
    getSiteData,
    getTailorMadePage,
    getTradeSignupPage,
};

export default contentApi;
