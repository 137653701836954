import classNames from 'classnames';
import styles from './CheckboxField.module.scss';

export interface CheckboxFieldProps {
    isChecked: boolean;
    label: React.ReactNode;
    id?: string;
    name?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string | number | boolean;
    isCount?: boolean;
    count?: string;
    isDisabled?: boolean;
    size?: 'normal' | 'small';
    className?: string;
}

const CheckboxField = ({
    isChecked,
    isDisabled = false,
    isCount = false,
    label,
    id,
    name,
    onChange,
    value,
    size = 'normal',
    count = ' (0)',
    className,
}: CheckboxFieldProps) => {
    return (
        <div
            className={classNames(
                styles.wrapper,
                {
                    [styles.disabled]: isDisabled,
                    [styles.small]: size === 'small',
                },
                className
            )}
        >
            <input
                type="checkbox"
                id={id}
                name={name}
                checked={isChecked}
                onChange={onChange}
                disabled={isDisabled}
                value={value !== undefined ? value.toString() : undefined}
            />
            <label className={styles.checkbox} htmlFor={id} />
            <label htmlFor={id} className={styles.label}>
                {label}
                {isCount && <span>{count}</span>}
            </label>
        </div>
    );
};

export default CheckboxField;
