export interface Group<TKey, TValue> {
    key: TKey;
    values: TValue[];
}

export function groupBy<TKey, TValue>(
    values: TValue[],
    keySelector: (value: TValue) => TKey
): Group<TKey, TValue>[] {
    const groups: Group<TKey, TValue>[] = [];

    for (let index = 0; index < values.length; index++) {
        const value = values[index];
        const key = keySelector(value);
        let group = groups.find(x => x.key === key);
        if (group === undefined) {
            group = {
                key,
                values: [value],
            };
            groups.push(group);
        } else {
            group.values.push(value);
        }
    }

    return groups;
}
