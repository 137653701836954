import { RequestTourDepartureFlightAction } from '@/redux/actions/tourDepartureFlight';
import { BasicApiResponse } from '@/endurance';
import { TourDepartureFlight } from '@/endurance/tourDepartureFlights';

import { call, put } from 'redux-saga/effects';
import {
    requestTourDepartureFlightFailure,
    requestTourDepartureFlightSuccess,
} from '../actions/tourDepartureFlight.actions';
import { logError, logErrorWithInfo } from '../../utils/sentry';
import { getTourDepartureFlight } from '../../apis/tourDepartureFlightApi';

export function* requestTourDepartureFlightSaga(
    action: RequestTourDepartureFlightAction
) {
    try {
        const result: BasicApiResponse<TourDepartureFlight> = yield call(
            getTourDepartureFlight,
            action.tourDepartureFlightId
        );
        if (result.success && !!result.result) {
            yield put(
                requestTourDepartureFlightSuccess(
                    action.tourDepartureFlightId,
                    result.result
                )
            );
        } else {
            yield put(
                requestTourDepartureFlightFailure(
                    action.tourDepartureFlightId,
                    result.errors
                        ? result.errors.join(', ')
                        : `Unknown error getting tour departure flight for Id ${action.tourDepartureFlightId}`
                )
            );
            logErrorWithInfo(Error('Failed to fetch tour departure flight'), {
                tourDepartureFlightId: action.tourDepartureFlightId,
                result: result,
                errors: result.errors,
            });
        }
    } catch (e) {
        logError(e);
        yield put(
            requestTourDepartureFlightFailure(
                action.tourDepartureFlightId,
                `${e}`
            )
        );
    }
}
