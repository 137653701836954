import { TourDepartureFlight } from '@/endurance/tourDepartureFlights';
import {
    RequestTourDepartureFlightAction,
    RequestTourDepartureFlightSuccessAction,
    RequestTourDepartureFlightFailureAction,
} from '@/redux/actions/tourDepartureFlight';

import {
    REQUEST_TOUR_DEPARTURE_FLIGHT,
    REQUEST_TOUR_DEPARTURE_FLIGHT_SUCCESS,
    REQUEST_TOUR_DEPARTURE_FLIGHT_FAILURE,
} from './types';

export const requestTourDepartureFlight = (
    tourDepartureFlightId: number
): RequestTourDepartureFlightAction => ({
    type: REQUEST_TOUR_DEPARTURE_FLIGHT,
    tourDepartureFlightId,
});

export const requestTourDepartureFlightSuccess = (
    tourDepartureFlightId: number,
    flight: TourDepartureFlight
): RequestTourDepartureFlightSuccessAction => ({
    type: REQUEST_TOUR_DEPARTURE_FLIGHT_SUCCESS,
    tourDepartureFlightId,
    flight,
});

export const requestTourDepartureFlightFailure = (
    tourDepartureFlightId: number,
    error: string
): RequestTourDepartureFlightFailureAction => {
    return {
        type: REQUEST_TOUR_DEPARTURE_FLIGHT_FAILURE,
        tourDepartureFlightId,
        error,
    };
};
