import { BasicApiResponse } from '@/endurance';
import {
    TourDepartureFlight,
    TourDepartureFlightsResponse,
} from '@/endurance/tourDepartureFlights';

import queryString from 'qs';
import { getResponse } from './enduranceApi';

export function getTourDepartureFlight(tourDepartureFlightId: number) {
    return getResponse<BasicApiResponse<TourDepartureFlight>>(
        `/flights/tourDepartureFlights/${tourDepartureFlightId}`
    )
        .then(data => data)
        .catch(error => {
            throw error;
        });
}

export function getTourDepartureLeadInFlight(
    tourDepartureId: number,
    departureAirportCode: string,
    currencyCode: string
) {
    const params = {
        departureAirportCode: departureAirportCode,
        currencyCode: currencyCode,
    };

    const paramsString = queryString.stringify(params, {
        addQueryPrefix: true,
    });

    return getResponse<BasicApiResponse<TourDepartureFlight>>(
        `/flights/tourDepartureFlights/${tourDepartureId}/leadInFlight${paramsString}`
    )
        .then(data => data)
        .catch(error => {
            throw error;
        });
}

export function getTourDepartureFlights(
    tourDepartureId: number,
    departureAirportCode: string,
    currencyCode: string
) {
    const params = {
        tourDepartureId: tourDepartureId,
        departureAirportCode: departureAirportCode,
        currencyCode: currencyCode,
    };

    const paramsString = queryString.stringify(params, {
        addQueryPrefix: true,
    });

    return getResponse<BasicApiResponse<TourDepartureFlightsResponse>>(
        `/flights/tourDepartureFlights${paramsString}`
    )
        .then(data => data)
        .catch(error => {
            throw error;
        });
}
