import { useState } from 'react';
import { PrimaryButton, PrimaryInverseButton } from '../../common/Button';
import classNames from 'classnames';
import CookieManager from '../CookieManager/CookieManager';
import { Link } from 'react-router-dom';
import { useBodyClass } from '../../../hooks/useBodyClass';
import { useCookieAcceptance } from '../../../contexts/CookieAcceptanceContext';
import { isPreRender } from '../../../utils/config';
import styles from './CookieBanner.module.scss';

export interface CookieBannerProps {
    className?: string;
}

function CookieBanner({ className }: CookieBannerProps) {
    const { hasSetCookiePreferences, onAcceptAllCookies } =
        useCookieAcceptance();
    const [openPreferences, setOpenPreferences] = useState(false);
    useBodyClass(
        styles.isCookieOpen,
        !hasSetCookiePreferences && !isPreRender()
    );

    if (hasSetCookiePreferences || isPreRender()) {
        return null;
    }

    return (
        <div className={classNames(styles.cookieBannerPage, className)}>
            <div className={styles.cookieBanner}>
                <p className={styles.cookiesMessage}>
                    Sometimes we use cookies to provide additional site
                    functionality, click to accept or go{' '}
                    <Link to="/cookies" aria-label="View cookie policy here">
                        here
                    </Link>{' '}
                    to read the cookie policy
                </p>
                <div className={styles.bannerButtons}>
                    <PrimaryButton
                        className={styles.acceptCookiesBtn}
                        onClick={handleAcceptCookies}
                    >
                        Accept Cookies
                    </PrimaryButton>
                    <PrimaryInverseButton
                        className={styles.manageCookiesBtn}
                        onClick={handleOpenPreferences}
                    >
                        Manage Preferences
                    </PrimaryInverseButton>
                </div>
            </div>
            <CookieManager
                isOpen={openPreferences}
                onClose={handleOpenPreferences}
            />
        </div>
    );

    function handleAcceptCookies() {
        onAcceptAllCookies();
    }

    function handleOpenPreferences() {
        setOpenPreferences(!openPreferences);
    }
}

export default CookieBanner;
