import { call, put, select } from 'redux-saga/effects';
import checkoutApi from '../../apis/checkoutApi';
import { stripeKey } from '../../utils/config';
import {
    createBookingSuccess,
    createBookingFailure,
    requestPaymentPlanSuccess,
    requestPaymentPlanFailure,
    applyPromoCodeSuccess,
    applyPromoCodeFailure,
    checkoutPriceChange,
} from '../actions/checkout.actions';
import { selectCheckoutSummary } from '../selectors/checkout.selectors';
import { logErrorWithCustomMessage } from '../../utils/sentry';
import ConversionCategory from '../../utils/analytics/enums/ConversionCategory';
import { sendEvent, sendEventV2 } from '../../utils/analytics/analyticsService';
import {
    Page,
    Area,
    InteractionItem,
    Action,
} from '../../utils/analytics/enums';
import Cookies from 'js-cookie';

export const getState = state => state;
export function createBooking(request) {
    return checkoutApi.createBooking(request);
}

export function confirmPayment(paymentProviderToken, bookingReference) {
    try {
        const stripe = window.Stripe(stripeKey);
        if (stripe !== undefined) {
            return stripe
                .handleCardAction(paymentProviderToken)
                .then(cardActionResponse => {
                    if (!cardActionResponse.error) {
                        return checkoutApi.confirmPayment(
                            cardActionResponse.paymentIntent.id,
                            bookingReference
                        );
                    } else {
                        return {
                            state: 'Failed',
                            message: cardActionResponse.error.message,
                            error: cardActionResponse.error.code,
                        };
                    }
                });
        } else {
            return {
                state: 'Failed',
                error: 'Stripe not found when confirming payment',
            };
        }
    } catch (error) {
        return {
            state: 'Failed',
            error,
            message: 'Unexpected error while confirming payment',
        };
    }
}

export function* createBookingRequest(action) {
    try {
        const createBookingResponse = yield call(
            createBooking,
            action.data.request
        );

        if (createBookingResponse.state === 'Success') {
            yield put(
                createBookingSuccess(
                    createBookingResponse.bookingReference,
                    createBookingResponse.conversionId
                )
            );
        } else if (
            createBookingResponse.state === 'PriceChange' &&
            createBookingResponse.priceChange
        ) {
            yield put(
                checkoutPriceChange(
                    createBookingResponse.priceChange.oldPrice,
                    createBookingResponse.priceChange.newPrice,
                    createBookingResponse.priceChange.paymentPlans,
                    createBookingResponse.bookingReference
                )
            );
        } else if (createBookingResponse.state === 'RequiresAction') {
            const confirmPaymentResponse = yield call(
                confirmPayment,
                createBookingResponse.paymentProviderClientSecret,
                createBookingResponse.bookingReference
            );
            if (confirmPaymentResponse.state === 'Success') {
                yield put(
                    createBookingSuccess(
                        confirmPaymentResponse.bookingReference,
                        confirmPaymentResponse.conversionId
                    )
                );
            } else {
                yield put(
                    createBookingFailure(
                        confirmPaymentResponse.error,
                        confirmPaymentResponse.message
                    )
                );
            }
        } else {
            yield put(
                createBookingFailure(
                    createBookingResponse.error,
                    createBookingResponse.message
                )
            );
        }
    } catch (error) {
        yield put(
            createBookingFailure(
                `${error}`,
                'Exception thrown when creating booking'
            )
        );
    }
}

export function* requestPaymentPlan(action) {
    const checkoutSummary = action.checkoutSummary;
    const paymentPlanRequest = {
        currencyCode: checkoutSummary.currencyCode,
        deposit: checkoutSummary.totalDeposit,
        total: checkoutSummary.total,
        fullBalanceDue: checkoutSummary.isFullBalanceDue,
        balanceDueDate: checkoutSummary.balanceDueDate,
        billingItems: checkoutSummary.lineItems.map(lineItem => ({
            price: lineItem.price,
            dateDue: checkoutSummary.balanceDueDate,
            quantity: lineItem.quantity,
        })),
    };

    try {
        const accessToken = Cookies.get('access-token');
        const result = yield call(
            checkoutApi.getPaymentPlan,
            paymentPlanRequest,
            accessToken
        );
        if (result.success) {
            yield put(requestPaymentPlanSuccess(result.paymentPlans));
        } else {
            yield put(
                requestPaymentPlanFailure(result.statusCode, result.errors)
            );
        }
    } catch (e) {
        yield put(requestPaymentPlanFailure(e));
    }
}

export function* applyPromoCode(action) {
    try {
        const result = yield call(checkoutApi.applyPromoCode, action.promoCode);
        if (result.success) {
            sendEvent(
                ConversionCategory.promoCodes,
                'Applied',
                action.promoCode
            );

            sendEventV2(
                Page.tourCheckout,
                Area.checkoutSideBar,
                InteractionItem.promoCode,
                Action.submit,
                {
                    success: true,
                    promoCode: action.promoCode,
                    discount: result.discount,
                }
            );

            yield put(applyPromoCodeSuccess(result));
            const checkoutSummary = yield select(selectCheckoutSummary);
            yield requestPaymentPlan({ checkoutSummary });
        } else {
            sendEvent(
                ConversionCategory.promoCodes,
                'Failed',
                action.promoCode
            );

            sendEventV2(
                Page.tourCheckout,
                Area.checkoutSideBar,
                InteractionItem.promoCode,
                Action.submit,
                {
                    success: false,
                    promoCode: action.promoCode,
                    errors: result.errors,
                }
            );

            yield put(applyPromoCodeFailure(result));
        }
    } catch (e) {
        logErrorWithCustomMessage(e, 'Failed to apply promo code');
        yield put(applyPromoCodeFailure(e));
    }
}
