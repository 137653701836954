import classNames from 'classnames';
import CircularIconButton from '../../../common/CircularIconButton/CircularIconButton';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';
import styles from './MobileSearchButton.module.scss';

export interface MobileSearchButtonProps {
    className?: string;
}

function MobileSearchButton({ className }: MobileSearchButtonProps) {
    return (
        <CircularIconButton
            className={classNames(styles.mobileSearchButton, className)}
            icon="search"
            onClick={handleClick}
        />
    );

    function handleClick() {
        sendEventV2(
            Page.header,
            Area.navigation,
            InteractionItem.searchButton,
            Action.click,
            null
        );
    }
}

export default MobileSearchButton;
