import { SuggestionType } from './SuggestionType';
import { Suggestion } from './Suggestion';

export const landmarks: Suggestion[] = [
    { name: 'Machu Picchu', type: SuggestionType.Landmark, isPopular: true },
    {
        name: 'Great Wall Of China',
        type: SuggestionType.Landmark,
        isPopular: false,
    },
    {
        name: 'Northern lights',
        type: SuggestionType.Landmark,
        isPopular: false,
    },
    { name: 'Route 66', type: SuggestionType.Landmark, isPopular: false },
    { name: 'Chichen Itza', type: SuggestionType.Landmark, isPopular: false },
    { name: 'Pyramids', type: SuggestionType.Landmark, isPopular: true },
    { name: 'Petra', type: SuggestionType.Landmark, isPopular: false },
    { name: 'Taj Mahal', type: SuggestionType.Landmark, isPopular: false },
    { name: 'Angkor Watt', type: SuggestionType.Landmark, isPopular: false },
    {
        name: 'The Forbidden City',
        type: SuggestionType.Landmark,
        isPopular: false,
    },
    { name: 'Stonehenge', type: SuggestionType.Landmark, isPopular: false },
    { name: 'Baobab Avenue', type: SuggestionType.Landmark, isPopular: false },
    { name: 'Ayutthaya', type: SuggestionType.Landmark, isPopular: false },
    { name: 'Sigiriya', type: SuggestionType.Landmark, isPopular: false },
    {
        name: 'Cliffs of Moher',
        type: SuggestionType.Landmark,
        isPopular: false,
    },
    { name: 'Niagara Falls', type: SuggestionType.Landmark, isPopular: false },
    {
        name: 'The Golden Temple',
        type: SuggestionType.Landmark,
        isPopular: false,
    },
    { name: 'Alhambra', type: SuggestionType.Landmark, isPopular: false },
    {
        name: "Giant's Causeway",
        type: SuggestionType.Landmark,
        isPopular: false,
    },
    {
        name: 'The Great Temple',
        type: SuggestionType.Landmark,
        isPopular: false,
    },
    { name: 'Abu Simbel', type: SuggestionType.Landmark, isPopular: false },
    {
        name: 'Christ The Redeemer',
        type: SuggestionType.Landmark,
        isPopular: false,
    },
    { name: 'The Acropolis', type: SuggestionType.Landmark, isPopular: false },
];
