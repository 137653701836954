import classNames from 'classnames';
import CircularIconButton from '../../../common/CircularIconButton/CircularIconButton';
import styles from './MyAccountButton.module.scss';

export interface MyAccountButtonProps {
    className?: string;
}

function MyAccountButton({ className }: MyAccountButtonProps) {
    return (
        <CircularIconButton
            className={classNames(styles.myAccountButton, className)}
            icon="userSolid"
            onClick={handleClick}
        />
    );

    function handleClick() {}
}

export default MyAccountButton;
