import { useState, ChangeEvent, SyntheticEvent } from 'react';
import classNames from 'classnames';
import CheckboxField from '../../common/CheckboxField/CheckboxField';
import { PrimaryButton, PrimaryInverseButton } from '../../common/Button';
import { useCookieAcceptance } from '../../../contexts/CookieAcceptanceContext';
import styles from './CookieManager.module.scss';

export interface CookieManagerProps {
    isOpen: boolean;
    onClose: () => void;
    className?: string;
}

function CookieManager({ className, isOpen, onClose }: CookieManagerProps) {
    const { onAcceptSpecificCookies } = useCookieAcceptance();
    const [functionalCookiesAccepted, setFunctionalCookiesAccepted] =
        useState(false);

    return (
        <div
            className={classNames(styles.cookieManagerPage, {
                [styles.hideCookieManager]: !isOpen,
            })}
        >
            <form
                className={classNames(className, styles.cookieManager)}
                onSubmit={handleSavePreferences}
            >
                <div className={styles.header}>
                    <h3>Manage Cookie Preferences</h3>
                </div>
                <h4>Essential Cookies</h4>
                <div className={styles.essentialCookies}>
                    <p>
                        Essential cookies are used to make our site function
                        properly, without these we wouldn't be able to provide
                        our service.
                    </p>
                    <CheckboxField
                        className={styles.checkbox}
                        id="essential"
                        label=""
                        name="essential"
                        isChecked={true}
                        isDisabled={true}
                        onChange={(_: ChangeEvent<HTMLInputElement>) => {}}
                    />
                </div>
                <h4>Functional Cookies</h4>
                <div className={styles.functionalCookies}>
                    <p>
                        These cookies help us to improve our service and
                        performance across the site, they aren't essential, but
                        they do help provide a better experience for our
                        customers.
                    </p>
                    <CheckboxField
                        className={styles.checkbox}
                        id="functional"
                        label=""
                        name="functional"
                        isChecked={functionalCookiesAccepted}
                        onChange={handleFunctionalChanged}
                        isDisabled={false}
                    />
                </div>
                <div className={styles.buttons}>
                    <PrimaryInverseButton
                        className={styles.closeButton}
                        onClick={onClose}
                    >
                        Close
                    </PrimaryInverseButton>
                    <PrimaryButton
                        className={styles.saveButton}
                        onClick={handleSavePreferences}
                        type="submit"
                    >
                        Save & Exit
                    </PrimaryButton>
                </div>
            </form>
        </div>
    );

    function handleFunctionalChanged(event: ChangeEvent<HTMLInputElement>) {
        setFunctionalCookiesAccepted(event.target.checked);
    }

    function handleSavePreferences(event: SyntheticEvent) {
        event.preventDefault();
        onAcceptSpecificCookies(functionalCookiesAccepted);
        onClose();
    }
}
export default CookieManager;
