import { MouseEvent } from 'react';
import classNames from 'classnames';
import Button, { ButtonProps } from '../Button/Button';
import styles from './LoadingButton.module.scss';

const LoadingButton = ({ className, onClick, ...props }: ButtonProps) => {
    return (
        <Button
            className={classNames(styles.loadingButton, className)}
            onClick={(e: MouseEvent<HTMLElement>) => {
                e.preventDefault();
            }}
            {...props}
        >
            <div className={classNames(styles.loader)} />
        </Button>
    );
};

export default LoadingButton;
