import React, { PureComponent } from 'react';
import classNames from 'classnames';
import NavLinks from './NavLinks/NavLinks';
import Memberships from './Memberships/Memberships';
import PopularDestinations from './PopularDestinations/PopularDestinations';
import Activities from './Activities/Activities';
import NewsletterSubscribe from './NewsletterSubscribe/NewsletterSubscribe';
import { TapPhoneNumber } from '../common/TapContactInfo';
import debounce from '../../utils/debounce';
import travelExpert from '../../assets/images/travel-expert-toby.jpg';
import SocialBar from '../common/SocialBar/SocialBar';
import ExpandableSection from '../common/ExpandableSection/ExpandableSection';
import CurrencySelectButton from '../CurrencySelectButton/CurrencySelectButton';
import { Page } from '../../utils/analytics/enums';
import styles from './Footer.module.scss';

const fullYearCopyright = new Date().getFullYear();

class Footer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isWaiting: true,
            windowWidth: null,
        };
        this.debouncedHandleResize = this.debouncedHandleResize.bind(this);
    }

    debouncedHandleResize = debounce(() => {
        this.setState({ windowWidth: window.innerWidth });
    }, 200);

    UNSAFE_componentWillMount() {
        let self = this;
        setTimeout(function () {
            self.setState({ isWaiting: false });
        }, 1000);
        window.addEventListener('resize', this.debouncedHandleResize);
        window.addEventListener('load', this.debouncedHandleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedHandleResize);
        window.removeEventListener('load', this.debouncedHandleResize);
    }
    handleCurrencyDialog = () => {
        const { resetCurrency } = this.props;
        resetCurrency(null);
    };

    render() {
        return (
            <>
                <NewsletterSubscribe />
                <Memberships />
                <footer>
                    <div className={styles.contactUs}>
                        <SocialBar />
                    </div>
                    <div className={styles.talkToUs}>
                        <div className={styles.talkToUsToby}>
                            <img
                                src={travelExpert}
                                alt="Travel Expert"
                                loading="lazy"
                            />
                        </div>
                        <div className={styles.talkToUsDetails}>
                            <h4>Talk to our adventure experts</h4>
                            <TapPhoneNumber />
                            <small>Mon-Fri 9am-7pm</small>
                            <small>Sat-Sun 10am-4pm</small>
                        </div>
                    </div>
                    <div className={styles.linksContent}>
                        <div>
                            <ExpandableSection
                                key={'Explore by country'}
                                title={'Explore by country'}
                                className={styles.expandable}
                                THeader="h4"
                            >
                                <PopularDestinations />
                            </ExpandableSection>
                            <ExpandableSection
                                key={'Activities'}
                                title={'Activities'}
                                className={styles.expandable}
                                THeader="h4"
                            >
                                <Activities />
                            </ExpandableSection>
                        </div>
                        <div>
                            <ExpandableSection
                                key={'Links'}
                                title={'Links'}
                                className={styles.expandable}
                                THeader="h4"
                            >
                                <NavLinks />
                            </ExpandableSection>
                        </div>
                        <div>
                            <section className={styles.openingTimes}>
                                <h4>Opening Hours</h4>
                                <div>
                                    <ul>
                                        <li>Monday - Friday</li>
                                        <li>Saturday</li>
                                        <li>Sunday</li>
                                    </ul>
                                    <ul className={styles.times}>
                                        <li>9am - 7pm</li>
                                        <li>10am - 4pm</li>
                                        <li>Closed</li>
                                    </ul>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className={styles.footerCopyrightContainer}>
                        <ul className={styles.footerCopyright}>
                            <li className={styles.copyright}>
                                <small>
                                    © {fullYearCopyright} The Adventure People.
                                    All rights reserved.
                                </small>
                            </li>
                            <li className={styles.registration}>
                                <small>
                                    Registered in England and Wales, No:
                                    10367760 VAT No: 290274304 The Adventure
                                    People Trademark No: UK00002649873
                                </small>
                            </li>
                        </ul>
                        <CurrencySelectButton
                            page={Page.footer}
                            className={styles.currencyButton}
                        />
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;
