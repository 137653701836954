import classNames from 'classnames';
import Button, { ButtonProps } from '../Button/Button';
import styles from './TransparentButton.module.scss';

const TransparentButton = ({ className, ...props }: ButtonProps) => {
    return (
        <Button
            className={classNames(styles.transparentButton, className)}
            {...props}
        />
    );
};

export default TransparentButton;
