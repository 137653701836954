import {
    init as SentryInit,
    withScope,
    captureException,
    captureMessage,
    breadcrumbsIntegration,
} from '@sentry/browser';
import { appVersion, environment, isPreRender, isProduction } from './config';

const init = () => {
    SentryInit({
        dsn: 'https://7882ba3649464d07984ded83a5c4abb6@sentry.io/1383045',
        release: isProduction ? `v${appVersion}` : undefined,
        environment: environment,
        integrations: [breadcrumbsIntegration({ console: false })],
        beforeSend: event => {
            if (
                event !== undefined &&
                event.exception !== undefined &&
                event.exception.values !== undefined &&
                event.exception.values.length === 1
            ) {
                // filter out UnhandledRejection errors that have no information
                // https://github.com/getsentry/sentry-javascript/issues/3440
                const e = event.exception.values[0];
                if (
                    e.type === 'UnhandledRejection' &&
                    e.value ===
                        'Non-Error promise rejection captured with value: '
                ) {
                    return null;
                }
            } else if (isPreRender()) {
                event.environment = 'Prerender';
                event.user = { ...(event.user || {}), username: 'Prerender' };
            }
            return event;
        },
    });
};

export const logError = (error: any) => {
    withScope(scope => {
        scope.setExtra('User App Version', appVersion);
        captureException(error);
    });
};

export const logErrorWithInfo = (error: any, errorInfo: any = {}) => {
    if (Object.prototype.toString.call(errorInfo) !== '[object Object]') {
        errorInfo = {
            info: errorInfo,
        };
    }
    withScope(scope => {
        errorInfo.pageUrl =
            window.location && window.location.href
                ? window.location.href
                : null;
        Object.keys(errorInfo).forEach(key => {
            scope.setExtra(key, errorInfo[key]);
        });
        scope.setExtra('User App Version', appVersion);
        captureException(error);
    });
};

export const logErrorWithCustomMessage = (
    error: Error,
    message: string,
    errorInfo: any = undefined
) => {
    if (error instanceof Error && message !== undefined) {
        try {
            error.message = `${message}: ${error.message}`;
        } catch (error) {
            // In some older browsers Error.message is readonly so catch it so we still log
            console.warn('Error setting Error.message:', error); // eslint-disable-line no-console
        }
    } else if (message !== undefined) {
        error = new Error(message);
    }

    if (errorInfo !== undefined) {
        logErrorWithInfo(error, errorInfo);
    } else {
        logError(error);
    }
};

export const logMessage = (message: string) => {
    captureMessage(message);
};

export const logErrorMessage = (message: string) => {
    captureMessage(message, 'error');
};

const logger = {
    init,
    logError,
    logErrorWithInfo,
    logMessage,
    logErrorMessage,
};

export default logger;
