import classNames from 'classnames';
import { Suggestion } from '../../../../../data/Suggestion';
import Icon from '../../../../common/Icon/Icon';
import styles from './Option.module.scss';

export interface OptionProps {
    className?: string;
    value: Suggestion;
}
function Option({ className, value }: OptionProps) {
    return (
        <div className={classNames(className, styles.option)}>
            <Icon
                className={styles.icon}
                icon="mapMarker"
                width={20}
                height={20}
                color="3e2259"
            />
            <span>{value.name}</span>
        </div>
    );
}

export default Option;
