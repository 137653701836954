import RequestStatus from '../../helpers/enums/requestStatus';
import * as TYPES from '../actions/types';
import { formatISO, parseISO } from 'date-fns';

export const initialState = {
    product: {
        type: null,
        tourId: null,
        departureId: null,
        tourDepartureFlightId: null,
    },
    booking: {
        isSubmitting: false,
        isConfirmed: false,
        reference: null,
        paymentType: null,
        errors: [],
        hasPaymentError: false,
    },
    customerCount: 1,
    customerCountConfirmed: false,
    customers: null,
    address: null,
    promoCode: null,
    tourAccommodationType: 'Shared',
    tourSelectedRooms: {},
    allTravellersHaveSelectedRooms: false,
    paymentType: null,
    flights: null,
    accommodation: null,
    roomsSelected: null,
    paymentPlans: null,
    selectedPaymentPlan: null,
    priceChange: null,
};

const createCheckoutSession = (state, action) => ({
    ...initialState,
    product: {
        type: action.productType,
        tourId: action.tourId,
        departureId: action.departureId,
        tourDepartureFlightId: action.tourDepartureFlightId ?? null,
    },
});

const createBookingRequest = (state, action) => {
    return {
        ...state,
        booking: {
            isSubmitting: true,
            isConfirmed: false,
            reference: null,
            paymentType:
                action.data.request.paymentDetails.selectedPaymentOption,
            hasPaymentError: false,
        },
    };
};

const createBookingSuccess = (state, action) => {
    return {
        ...state,
        booking: {
            isSubmitting: false,
            isConfirmed: true,
            reference: action.bookingReference,
            paymentType: state.booking.paymentType,
            hasPaymentError: false,
            conversionId: action.conversionId,
        },
    };
};

const createBookingFailure = (state, action) => {
    return {
        ...state,
        booking: {
            isSubmitting: false,
            isConfirmed: false,
            reference: null,
            paymentType: state.booking.paymentType,
            hasPaymentError: true,
        },
    };
};

const setPriceChange = (state, action) => {
    return {
        ...state,
        booking: {
            isSubmitting: false,
            isConfirmed: false,
            reference: action.bookingReference,
            paymentType: state.booking.paymentType,
            hasPaymentError: false,
        },
        priceChange: {
            oldPrice: action.oldPrice,
            newPrice: action.newPrice,
            paymentPlans: action.newPaymentPlans,
        },
    };
};

const acceptPriceChange = (state, action) => {
    return {
        ...state,
        paymentPlans: state.priceChange.paymentPlans,
        priceChange: null,
    };
};

const updateCustomerDetails = (state, action) => {
    return {
        ...state,
        ...action.details,
        booking: initialState.booking,
    };
};

const requestPaymentPlan = state => ({
    ...state,
    paymentPlans: {
        loading: true,
    },
});

const requestPaymentPlanSuccess = (state, action) => {
    return {
        ...state,
        paymentPlans: {
            plans: action.data,
            loading: false,
        },
    };
};

const requestPaymentPlanFailure = state => {
    return {
        ...state,
        paymentPlans: {
            loading: false,
            plans: [],
            isAvailable: false,
        },
    };
};

const applyPromoCode = (state, action) => {
    return {
        ...state,
        promoCode: {
            ...state.promoCode,
            error: null,
            isSubmitting: true,
        },
    };
};

const applyPromoCodeSuccess = (state, action) => {
    return {
        ...state,
        promoCode: {
            discount: action.data.discount,
            code: action.data.promoCode,
            error: null,
            isSubmitting: false,
        },
    };
};

const applyPromoCodeFailure = (state, action) => {
    const error =
        (action.data.errors &&
            action.data.errors.length > 0 &&
            action.data.errors[0]) ||
        "We're having trouble applying your promo code at the moment. Please try again and contact us if the problem persists.";

    return {
        ...state,
        promoCode: {
            error: error,
            isSubmitting: false,
        },
    };
};

const addFlights = (state, action) => {
    return {
        ...state,
        booking: {
            ...state.booking,
            hasPaymentError: false,
        },
        product: { ...state.product, type: 'Package' },
        flights: action.flights,
        customerCount: action.travellerCount,
        customers: state.customers
            ? state.customers.slice(0, action.travellerCount)
            : state.customers,
        tourAccommodationType: initialState.tourAccommodationType,
        priceChange: null,
    };
};

const removeFlights = state => {
    return {
        ...state,
        product: {
            ...state.product,
            type: 'Tour',
        },
        flights: null,
    };
};

const setCustomerCount = (state, action) => {
    return {
        ...state,
        customerCount: action.count,
        tourAccommodationType: initialState.tourAccommodationType,
    };
};

const setCustomerCountFromFlightSearch = (state, action) => {
    return setCustomerCount(state, {
        count: action.request.adults,
    });
};

const setSelectedPaymentPlan = (state, action) => ({
    ...state,
    selectedPaymentPlan: action.paymentPlan,
});

const addAccommodation = (state, action) => ({
    ...state,
    accommodation: {
        ...action.accommodationDetails,
        pricingStatus: RequestStatus.LOADING,
    },
    customerCount: 1,
    customers: state.customers
        ? state.customers.filter(
              customer => customer.nationality || customer.dateOfBirth
          )
        : [],
    paymentType: null,
    monthlyPaymentPlan: null,
    product: {
        ...initialState.product,
        type: 'AccommodationOnly',
    },
});

const setAccommodationPrice = (state, action) => ({
    ...state,
    accommodation: {
        ...state.accommodation,
        supplierId: action.supplierId,
        supplierBasketToken: action.supplierBasketToken,
        checkInDate: action.checkInDate,
        checkOutDate: action.checkOutDate,
        boardBasis: action.boardBasis,
        roomType: action.roomType,
        guests: action.guests,
        importantInformation: action.importantInformation,
        cancellationPolicy: action.cancellationPolicy,
        roomPrice: action.roomPrice,
        freeCancellationDate: action.freeCancellationDate,
        isRefundable: action.isRefundable,
        pricingStatus: RequestStatus.SUCCESS,
    },
});

const setAccommodationFailed = (state, action) => ({
    ...state,
    accommodation: {
        ...state.accommodation,
        pricingStatus: RequestStatus.FAILED,
    },
});

const setRoomsSelected = (state, action) => {
    return {
        ...state,
        tourSelectedRooms: action.selectedRooms,
        allTravellersHaveSelectedRooms: action.allSelected,
        tourAccommodationType: 'Room',
    };
};

const setCustomerCountConfirmed = (state, action) => {
    return {
        ...state,
        customerCountConfirmed: action.isConfirmed,
    };
};

const setPackageSelected = (state, action) => {
    return {
        ...state,
        product: { ...state.product, type: 'Package' },
        flights: transformPackageFlightToCheckoutFlight(
            action.packageData.flight,
            action.prices
        ),
        booking: { ...state.booking, paymentType: action.paymentType },
        customerCount: action.travellerCount,
        customers: state.customers
            ? state.customers.slice(0, action.travellerCount)
            : state.customers,
        tourAccommodationType: initialState.tourAccommodationType,
        paymentType: action.paymentType,
    };
};
// const setCheckoutOrigin = (state, action) => {
//     return {
//         ...state,
//         checkoutOrigin: action.checkoutOrigin,
//     };
// };

function transformPackageFlightToCheckoutFlight(packageFlight, prices) {
    const outboundFirstLeg = packageFlight.outboundLegs[0];
    const outboundLastLeg =
        packageFlight.outboundLegs[packageFlight.outboundLegs.length - 1];
    const returnFirstLeg = packageFlight?.returnLegs[0] ?? null;
    const returnLastLeg =
        packageFlight.returnLegs[packageFlight.returnLegs.length - 1];

    const transformedFlight = {
        isCachedFlight: packageFlight.isCachedFlight,
        order: 0,
        outboundFlight: {
            arrivalAirport: {
                code: outboundLastLeg.arrivalAirportCode,
                name: outboundLastLeg.arrivalAirportName,
            },
            departureTime: combineDateAndTime(
                outboundFirstLeg.departureDate,
                outboundFirstLeg.departureTime
            ),
            arrivalTime: combineDateAndTime(
                outboundLastLeg.arrivalDate,
                outboundLastLeg.arrivalTime
            ),
            departureAirport: {
                code: outboundFirstLeg.departureAirportCode,
                name: outboundFirstLeg.departureAirportName,
            },

            legs: packageFlight.outboundLegs.map(leg => ({
                arrivalAirport: {
                    code: leg.arrivalAirportCode,
                    name: leg.arrivalAirportName,
                },
                arrivalDate: leg.arrivalDate,
                arrivalTime: leg.arrivalTime,
                departureAirport: {
                    code: leg.departureAirportCode,
                    name: leg.departureAirportName,
                },
                departureDate: leg.departureDate,
                departureTime: leg.departureTime,
                marketingCarrier: {
                    code: leg.carrier,
                    logo: null,
                    name: leg.carrier,
                },
                operatingCarrier: {
                    code: leg.carrier,
                    logo: null,
                    name: leg.carrier,
                },
            })),
            stopOverAirports: [],
            stops: 0,
        },
        returnFlight: {
            arrivalAirport: {
                code: returnLastLeg.arrivalAirportCode,
                name: returnLastLeg.arrivalAirportName,
            },
            departureTime: combineDateAndTime(
                returnFirstLeg.departureDate,
                returnFirstLeg.departureTime
            ),
            arrivalTime: combineDateAndTime(
                returnLastLeg.arrivalDate,
                returnLastLeg.arrivalTime
            ),
            departureAirport: {
                code: returnFirstLeg.departureAirportCode,
                name: returnFirstLeg.departureAirportName,
            },

            legs: packageFlight.returnLegs.map(leg => ({
                arrivalAirport: {
                    code: leg.arrivalAirportCode,
                    name: leg.arrivalAirportName,
                },
                arrivalDate: leg.arrivalDate,
                arrivalTime: leg.arrivalTime,
                departureAirport: {
                    code: leg.departureAirportCode,
                    name: leg.departureAirportName,
                },
                departureDate: leg.departureDate,
                departureTime: leg.departureTime,
                marketingCarrier: {
                    code: leg.carrier,
                    logo: null,
                    name: leg.carrier,
                },
                operatingCarrier: {
                    code: leg.carrier,
                    logo: null,
                    name: leg.carrier,
                },
            })),
            stopOverAirports: [],
            stops: 0,
        },
        supplierId: packageFlight.flightSupplierId,
        token: packageFlight.flightToken,
        totalPrice: prices.map(p => ({
            currencyCode: p.currencyCode,
            totalPrice: p.packagePrice,
            baseFlightPrice: p.flightPrice,
            baseTourPrice: p.tourPrice,
            balanceDueDate: p.balanceDueDate,
            deposit: p.deposit,
            atolFee: p.atolFee,
        })),
    };
    return transformedFlight;

    function combineDateAndTime(date, time) {
        const parsedDate = parseISO(date);
        const hours = parseInt(time.split(':')[0]);
        const minutes = parseInt(time.split(':')[1]);

        parsedDate.setHours(hours);
        parsedDate.setMinutes(minutes);

        return formatISO(parsedDate);
    }
}

const checkout = (state = initialState, action) => {
    const handlers = {
        [TYPES.CREATE_CHECKOUT_SESSION]: createCheckoutSession,
        [TYPES.CREATE_BOOKING_REQUEST]: createBookingRequest,
        [TYPES.CREATE_BOOKING_SUCCESS]: createBookingSuccess,
        [TYPES.CREATE_BOOKING_FAILURE]: createBookingFailure,
        [TYPES.CUSTOMER_DETAILS_CHANGED]: updateCustomerDetails,
        [TYPES.REQUEST_PAYMENT_PLAN]: requestPaymentPlan,
        [TYPES.REQUEST_PAYMENT_PLAN_SUCCESS]: requestPaymentPlanSuccess,
        [TYPES.REQUEST_PAYMENT_PLAN_FAILURE]: requestPaymentPlanFailure,
        [TYPES.APPLY_PROMO_CODE]: applyPromoCode,
        [TYPES.APPLY_PROMO_CODE_SUCCESS]: applyPromoCodeSuccess,
        [TYPES.APPLY_PROMO_CODE_FAILURE]: applyPromoCodeFailure,
        [TYPES.CHECKOUT_ADD_FLIGHTS]: addFlights,
        [TYPES.CHECKOUT_REMOVE_FLIGHTS]: removeFlights,
        [TYPES.CHECKOUT_SET_CUSTOMER_COUNT]: setCustomerCount,
        [TYPES.CHECKOUT_SET_PAYMENT_TYPE]: setSelectedPaymentPlan,
        [TYPES.FLIGHTS_SEARCH_SUCCESS]: setCustomerCountFromFlightSearch,
        [TYPES.ACCOM_CONFIRM_PRICE_REQUEST]: addAccommodation,
        [TYPES.ACCOM_CONFIRM_PRICE_SUCCESS]: setAccommodationPrice,
        [TYPES.ACCOM_CONFIRM_PRICE_FAILURE]: setAccommodationFailed,
        [TYPES.CHECKOUT_TOUR_SET_ROOMS_SELECTED]: setRoomsSelected,
        [TYPES.SET_PACKAGE_SELECTED]: setPackageSelected,
        [TYPES.CHECKOUT_PRICE_CHANGE]: setPriceChange,
        [TYPES.CHECKOUT_PRICE_CHANGE_ACCEPT]: acceptPriceChange,
        [TYPES.CHECKOUT_TOUR_CUSTOMER_COUNT_CONFIRMED]:
            setCustomerCountConfirmed,
    };

    return handlers[action.type] ? handlers[action.type](state, action) : state;
};

export default checkout;
