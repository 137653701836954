import { TourDepartureFlightState } from '@/redux/TourDepartureFlightState';
import { Action } from '@/redux/actions/Action';
import {
    RequestTourDepartureFlightAction,
    RequestTourDepartureFlightFailureAction,
    RequestTourDepartureFlightSuccessAction,
} from '@/redux/actions/tourDepartureFlight';
import * as TYPES from '../actions/types';

const initialState: TourDepartureFlightState = {
    loading: {},
    flights: {},
    errors: {},
};

export function requestTourDepartureFlight(
    state: TourDepartureFlightState,
    action: RequestTourDepartureFlightAction
): TourDepartureFlightState {
    return {
        ...state,
        errors: { ...state.errors, [action.tourDepartureFlightId]: undefined },
        loading: { ...state.loading, [action.tourDepartureFlightId]: true },
    };
}

export function requestTourDepartureFlightSuccess(
    state: TourDepartureFlightState,
    action: RequestTourDepartureFlightSuccessAction
): TourDepartureFlightState {
    return {
        ...state,
        loading: { ...state.loading, [action.tourDepartureFlightId]: false },
        flights: {
            ...state.flights,
            [action.tourDepartureFlightId]: action.flight,
        },
    };
}

export function requestTourDepartureFlightFailure(
    state: TourDepartureFlightState,
    action: RequestTourDepartureFlightFailureAction
): TourDepartureFlightState {
    return {
        ...state,
        errors: {
            ...state.errors,
            [action.tourDepartureFlightId]: action.error,
        },
        loading: { ...state.loading, [action.tourDepartureFlightId]: false },
    };
}

const flights = (
    state: TourDepartureFlightState = initialState,
    action: Action
): TourDepartureFlightState => {
    const handlers: {
        [key: string]: (
            s: TourDepartureFlightState,
            action?: Action
        ) => TourDepartureFlightState;
    } = {
        [TYPES.REQUEST_TOUR_DEPARTURE_FLIGHT]: requestTourDepartureFlight,
        [TYPES.REQUEST_TOUR_DEPARTURE_FLIGHT_SUCCESS]:
            requestTourDepartureFlightSuccess,
        [TYPES.REQUEST_TOUR_DEPARTURE_FLIGHT_FAILURE]:
            requestTourDepartureFlightFailure,
    };
    const handler: (
        site: TourDepartureFlightState,
        action?: Action
    ) => TourDepartureFlightState = handlers[action.type];

    return handler ? handler(state, action) : state;
};

export default flights;
