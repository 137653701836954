import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { setCurrency } from '../../../../redux/actions/site.actions';
import styles from './MobileCurrencySelector.module.scss';
import { sendEvent } from '../../../../utils/analytics/analyticsService';
import ConversionCategory from '../../../../utils/analytics/enums/ConversionCategory';

export const MobileCurrencySelector = ({
    currencies,
    selectedCurrency,
    setCurrency,
    className,
    ...props
}) => {
    if (currencies.length === 0) {
        return (
            <div className={classNames(styles.loader, className)} {...props}>
                Currency: Loading
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.mobileCurrencySelector, className)}
            {...props}
        >
            {currencies.length > 1 ? (
                <>
                    <label
                        className={styles.currencyLabel}
                        htmlFor="currencies-list"
                    >
                        Currency
                    </label>
                    <select
                        id="currencies-list"
                        className={styles.currencySelect}
                        value={selectedCurrency}
                        onChange={e => {
                            sendEvent(
                                ConversionCategory.localisation,
                                'Set from mobile currency selector',
                                e.target.value
                            );
                            setCurrency(
                                currencies.find(c => c.code === e.target.value)
                            );
                        }}
                    >
                        {currencies.map(({ code, name, symbol }) => (
                            <option key={code} value={code}>
                                {symbol + ' - ' + name}
                            </option>
                        ))}
                    </select>
                    <label
                        className={styles.currencyFlag}
                        htmlFor="currencies-list"
                    >
                        <img
                            alt=""
                            src={getCurrencyFlag(currencies, selectedCurrency)}
                        />
                    </label>
                </>
            ) : (
                <div>{getCurrencySymbol(currencies, selectedCurrency)}</div>
            )}
        </div>
    );
};

MobileCurrencySelector.propTypes = {
    currencies: propTypes.arrayOf(
        propTypes.shape({
            code: propTypes.string.isRequired,
            name: propTypes.string.isRequired,
            symbol: propTypes.string.isRequired,
        })
    ),
    selectedCurrency: propTypes.string.isRequired,
    setCurrency: propTypes.func.isRequired,
};

const mapStateToProps = ({ site }) => {
    return {
        currencies: site.currencies,
        selectedCurrency: site.selectedCurrency ? site.selectedCurrency : 'GBP',
    };
};

const mapDispatchToProps = dispatch => ({
    setCurrency: params => dispatch(setCurrency(params)),
});

const getCurrencySymbol = (currencies, selectedCurrency) => {
    return currencies.find(c => c.code === selectedCurrency)?.symbol ?? '';
};

const getCurrencyFlag = (currencies, selectedCurrency) => {
    const currency = currencies.find(
        c => c.code === (selectedCurrency ? selectedCurrency : 'GBP')
    );
    return currency ? currency.flag : '';
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileCurrencySelector);
