import React, { useState } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { setCurrency } from '../../../../redux/actions/site.actions';
import styles from './CurrencySelector.module.scss';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import {
    Page,
    Action,
    InteractionItem,
    Area,
} from '../../../../utils/analytics/enums/EventInteractionEnums';

export const CurrencySelector = ({
    isLoading,
    currencies,
    selectedCurrency,
    setCurrency,
    className,
    ...props
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const isLongSymbol =
        !isLoading &&
        getCurrencySymbol(currencies, selectedCurrency).length > 1;

    return (
        <div
            className={classNames(styles.currencySelector, className)}
            onBlur={() => setTimeout(() => setIsExpanded(false), 200)}
            {...props}
        >
            <button
                className={classNames({
                    [styles.selectCurrencyButton]: true,
                    [styles.isLongSymbol]: isLongSymbol,
                })}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {!isLoading && (
                    <>
                        <img
                            alt=""
                            className={styles.flag}
                            src={getCurrencyFlag(currencies, selectedCurrency)}
                            width="26px"
                            height="26px"
                        />
                        <span className={styles.symbol}>
                            {getCurrencySymbol(currencies, selectedCurrency)}
                        </span>
                        {currencies.length > 1 && (
                            <div
                                className={classNames({
                                    [styles.chevron]: true,
                                    [styles.isOpen]: isExpanded,
                                })}
                            />
                        )}
                    </>
                )}
            </button>

            {isExpanded && currencies.length > 1 && (
                <ul className={styles.currencyMenu}>
                    {currencies.map(currency => {
                        const { code, name, symbol, flag } = currency;
                        const isSelected = code === selectedCurrency;
                        const Type = isSelected ? 'div' : 'button';
                        const onClick = isSelected
                            ? null
                            : () => {
                                  sendEventV2(
                                      Page.Header,
                                      Area.currencySelector,
                                      InteractionItem.currencySelector,
                                      Action.click,
                                      { currencyCode: code }
                                  );
                                  setCurrency(currency);
                                  setIsExpanded(false);
                              };
                        return (
                            <li
                                className={classNames(styles.currencyMenuItem, {
                                    [styles.selected]: isSelected,
                                })}
                                key={code}
                            >
                                <Type onMouseDown={onClick}>
                                    <img
                                        alt=""
                                        className={styles.flag}
                                        src={flag}
                                    />
                                    <span className={styles.currencySymbol}>
                                        {symbol}
                                    </span>
                                    <span className={styles.currencyName}>
                                        {name}
                                    </span>
                                </Type>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

CurrencySelector.propTypes = {
    isLoading: propTypes.bool.isRequired,
    currencies: propTypes.arrayOf(
        propTypes.shape({
            code: propTypes.string.isRequired,
            name: propTypes.string.isRequired,
            symbol: propTypes.string.isRequired,
            flag: propTypes.string.isRequired,
        })
    ),
    selectedCurrency: propTypes.string,
    setCurrency: propTypes.func.isRequired,
};

const mapStateToProps = ({ site }) => {
    return {
        isLoading: site.isLoading,
        currencies: site.currencies,
        selectedCurrency: site.selectedCurrency ? site.selectedCurrency : 'GBP',
    };
};

const mapDispatchToProps = dispatch => ({
    setCurrency: params => dispatch(setCurrency(params)),
});

const getCurrencySymbol = (currencies, selectedCurrency) => {
    const currency = currencies.find(c => c.code === selectedCurrency);
    return currency ? currency.symbol : '£';
};

const getCurrencyFlag = (currencies, selectedCurrency) => {
    const currency = currencies.find(
        c => c.code === (selectedCurrency ? selectedCurrency : 'GBP')
    );

    return currency ? currency.flag : '';
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySelector);
