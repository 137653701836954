import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './DesktopLink.module.scss';

export interface DesktopLinkProps {
    className?: string;
    children: ReactNode;
}

function DesktopLink({ children, className }: DesktopLinkProps) {
    return (
        <li className={classNames(styles.desktopLink, className)}>
            <button>{children}</button>
        </li>
    );
}

export default DesktopLink;
