import { SuggestionType } from './SuggestionType';
import { SuggestionCountry } from './SuggestionCountry';

export const countries: SuggestionCountry[] = [
    {
        name: 'Albania',
        isoCode2: 'AL',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Algeria',
        isoCode2: 'DZ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Andorra',
        isoCode2: 'AD',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Antarctica',
        isoCode2: 'AQ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Antigua and Barbuda',
        isoCode2: 'AG',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Argentina',
        isoCode2: 'AR',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Armenia',
        isoCode2: 'AM',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Australia',
        isoCode2: 'AU',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Austria',
        isoCode2: 'AT',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Azerbaijan',
        isoCode2: 'AZ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Bahamas',
        isoCode2: 'BS',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Bahrain',
        isoCode2: 'BH',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Bangladesh',
        isoCode2: 'BD',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Barbados',
        isoCode2: 'BB',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Belgium',
        isoCode2: 'BE',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Belize',
        isoCode2: 'BZ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Benin',
        isoCode2: 'BJ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Bermuda',
        isoCode2: 'BM',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Bhutan',
        isoCode2: 'BT',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Bolivia',
        isoCode2: 'BO',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Bosnia and Herzegovina',
        isoCode2: 'BA',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Botswana',
        isoCode2: 'BW',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Brazil',
        isoCode2: 'BR',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Brunei',
        isoCode2: 'BN',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Bulgaria',
        isoCode2: 'BG',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Cambodia',
        isoCode2: 'KH',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Cameroon',
        isoCode2: 'CM',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Canada',
        isoCode2: 'CA',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Cape Verde',
        isoCode2: 'CV',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Cayman Islands',
        isoCode2: 'KY',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Chile',
        isoCode2: 'CL',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'China',
        isoCode2: 'CN',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Hong Kong',
        isoCode2: 'HK',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Colombia',
        isoCode2: 'CO',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Congo',
        isoCode2: 'CG',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Costa Rica',
        isoCode2: 'CR',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: "Côte d'Ivoire",
        isoCode2: 'CI',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Croatia',
        isoCode2: 'HR',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Cuba',
        isoCode2: 'CU',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Cyprus',
        isoCode2: 'CY',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Czech Republic',
        isoCode2: 'CZ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Denmark',
        isoCode2: 'DK',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Dominica',
        isoCode2: 'DM',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Dominican Republic',
        isoCode2: 'DO',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Ecuador',
        isoCode2: 'EC',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Egypt',
        isoCode2: 'EG',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'El Salvador',
        isoCode2: 'SV',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Equatorial Guinea',
        isoCode2: 'GQ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Estonia',
        isoCode2: 'EE',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Ethiopia',
        isoCode2: 'ET',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Fiji',
        isoCode2: 'FJ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Finland',
        isoCode2: 'FI',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'France',
        isoCode2: 'FR',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'French Guiana',
        isoCode2: 'GF',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'French Polynesia',
        isoCode2: 'PF',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'The Gambia',
        isoCode2: 'GM',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Georgia',
        isoCode2: 'GE',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Germany',
        isoCode2: 'DE',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Ghana',
        isoCode2: 'GH',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Gibraltar',
        isoCode2: 'GI',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Greece',
        isoCode2: 'GR',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Greenland',
        isoCode2: 'GL',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Grenada',
        isoCode2: 'GD',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Guadeloupe',
        isoCode2: 'GP',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Guam',
        isoCode2: 'GU',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Guatemala',
        isoCode2: 'GT',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Guernsey',
        isoCode2: 'GG',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Guinea',
        isoCode2: 'GN',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Guyana',
        isoCode2: 'GY',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Honduras',
        isoCode2: 'HN',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Hungary',
        isoCode2: 'HU',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Iceland',
        isoCode2: 'IS',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'India',
        isoCode2: 'IN',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Indonesia',
        isoCode2: 'ID',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Ireland',
        isoCode2: 'IE',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Isle of Man',
        isoCode2: 'IM',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Israel',
        isoCode2: 'IL',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Italy',
        isoCode2: 'IT',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Jamaica',
        isoCode2: 'JM',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Japan',
        isoCode2: 'JP',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Jersey',
        isoCode2: 'JE',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Jordan',
        isoCode2: 'JO',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Kazakhstan',
        isoCode2: 'KZ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Kenya',
        isoCode2: 'KE',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'South Korea',
        isoCode2: 'KR',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Kuwait',
        isoCode2: 'KW',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Kyrgyzstan',
        isoCode2: 'KG',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Laos',
        isoCode2: 'LA',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Latvia',
        isoCode2: 'LV',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Liberia',
        isoCode2: 'LR',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Liechtenstein',
        isoCode2: 'LI',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Lithuania',
        isoCode2: 'LT',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Luxembourg',
        isoCode2: 'LU',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Macedonia',
        isoCode2: 'MK',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Madagascar',
        isoCode2: 'MG',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Malaysia',
        isoCode2: 'MY',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Maldives',
        isoCode2: 'MV',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Malta',
        isoCode2: 'MT',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Mauritius',
        isoCode2: 'MU',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Mexico',
        isoCode2: 'MX',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Moldova',
        isoCode2: 'MD',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Mongolia',
        isoCode2: 'MN',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Montenegro',
        isoCode2: 'ME',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Morocco',
        isoCode2: 'MA',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Mozambique',
        isoCode2: 'MZ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Myanmar',
        isoCode2: 'MM',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Namibia',
        isoCode2: 'NA',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Nepal',
        isoCode2: 'NP',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Netherlands',
        isoCode2: 'NL',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'New Zealand',
        isoCode2: 'NZ',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Nicaragua',
        isoCode2: 'NI',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Nigeria',
        isoCode2: 'NG',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Norway',
        isoCode2: 'NO',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Oman',
        isoCode2: 'OM',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Pakistan',
        isoCode2: 'PK',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Panama',
        isoCode2: 'PA',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Papua New Guinea',
        isoCode2: 'PG',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Paraguay',
        isoCode2: 'PY',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Peru',
        isoCode2: 'PE',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Philippines',
        isoCode2: 'PH',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Poland',
        isoCode2: 'PL',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Portugal',
        isoCode2: 'PT',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Puerto Rico',
        isoCode2: 'PR',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Qatar',
        isoCode2: 'QA',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Romania',
        isoCode2: 'RO',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Rwanda',
        isoCode2: 'RW',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Saint Lucia',
        isoCode2: 'LC',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Samoa',
        isoCode2: 'WS',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Saudi Arabia',
        isoCode2: 'SA',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Senegal',
        isoCode2: 'SN',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Serbia',
        isoCode2: 'RS',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Seychelles',
        isoCode2: 'SC',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Singapore',
        isoCode2: 'SG',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Slovakia',
        isoCode2: 'SK',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Slovenia',
        isoCode2: 'SI',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Solomon Islands',
        isoCode2: 'SB',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Somalia',
        isoCode2: 'SO',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'South Africa',
        isoCode2: 'ZA',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'South Georgia',
        isoCode2: 'GS',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Spain',
        isoCode2: 'ES',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Sri Lanka',
        isoCode2: 'LK',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Svalbard',
        isoCode2: 'SJ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Swaziland',
        isoCode2: 'SZ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Sweden',
        isoCode2: 'SE',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Switzerland',
        isoCode2: 'CH',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Tajikistan',
        isoCode2: 'TJ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Tanzania',
        isoCode2: 'TZ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Thailand',
        isoCode2: 'TH',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Timor-Leste',
        isoCode2: 'TL',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Togo',
        isoCode2: 'TG',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Tonga',
        isoCode2: 'TO',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Trinidad and Tobago',
        isoCode2: 'TT',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Tunisia',
        isoCode2: 'TN',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Turkey',
        isoCode2: 'TR',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Turkmenistan',
        isoCode2: 'TM',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Uganda',
        isoCode2: 'UG',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'United Arab Emirates',
        isoCode2: 'AE',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'United Kingdom',
        isoCode2: 'GB',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'United States of America',
        isoCode2: 'US',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Uruguay',
        isoCode2: 'UY',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Uzbekistan',
        isoCode2: 'UZ',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Vietnam',
        isoCode2: 'VN',
        type: SuggestionType.Country,
        isPopular: true,
    },
    {
        name: 'Zambia',
        isoCode2: 'ZM',
        type: SuggestionType.Country,
        isPopular: false,
    },
    {
        name: 'Zimbabwe',
        isoCode2: 'ZW',
        type: SuggestionType.Country,
        isPopular: false,
    },
];
