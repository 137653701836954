import classNames from 'classnames';
import CircularIconButton from '../../../common/CircularIconButton/CircularIconButton';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';
import styles from './DesktopPhoneButton.module.scss';

export interface DesktopPhoneButtonProps {
    className?: string;
}

function DesktopPhoneButton({ className }: DesktopPhoneButtonProps) {
    return (
        <CircularIconButton
            className={classNames(styles.desktopPhoneButton, className)}
            icon="phoneSolid"
            as="button"
            aria-label="Open contact us details"
            onClick={handleClick}
        />
    );

    function handleClick() {
        sendEventV2(
            Page.header,
            Area.navigation,
            InteractionItem.phoneLink,
            Action.click,
            null
        );
    }
}

export default DesktopPhoneButton;
