export function getActivityLinks(activities) {
    const activityLinks = [
        {
            type: 'popular',
            to: '/activities/popular',
            children: 'Popular',
            links: [],
        },
        {
            type: 'youth',
            to: '/activities/18-30-somethings',
            children: "Youth 20's - 30's",
            links: [],
        },
        {
            type: 'older',
            to: '/activities/40s-and-over',
            children: '40s and over',
            links: [],
        },
        {
            type: 'solo',
            to: '/activities/solo-travellers',
            children: 'Solo Travellers',
            links: [],
        },
        {
            type: 'family',
            to: '/activities/family',
            children: 'Family',
            links: [],
        },
        {
            type: 'gap',
            to: '/activities/gap-year',
            children: 'GAP Year',
            links: [],
        },
        {
            type: 'walking',
            to: '/activities/trekking',
            children: 'Trekking',
            links: [],
        },
        {
            type: 'cycling',
            to: '/activities/cycling',
            children: 'Cycling',
            links: [],
        },
        {
            type: 'sailing',
            to: '/activities/sailing',
            children: 'Sailing',
            links: [],
        },
        {
            type: 'multi-activity',
            to: '/activities/multi-activity',
            children: 'Multi Activity',
            links: [],
        },
        {
            type: 'wildlife',
            to: '/activities/wildlife',
            children: 'Wildlife',
            links: [],
        },
        {
            type: 'premium',
            to: '/activities/premium',
            children: 'Premium',
            links: [],
        },
        {
            type: 'polar',
            to: '/activities/polar-exploring',
            children: 'Polar',
            links: [],
        },
    ];

    const links = activities
        .map(({ slug, title, isPopular, isIconic, type, imageUrl }) => ({
            link: {
                to: `/activities/${slug}`,
                children: title,
                imageUrl,
            },
            title,
            isPopular,
            isIconic,
            type,
        }))
        .sort(sortByTitle);

    links.forEach(({ link, isPopular, isIconic, type, imageUrl }) => {
        if (isPopular) {
            activityLinks
                .find(({ type }) => type === 'popular')
                .links.push(link);
        }
    });

    return activityLinks;
}

function sortByTitle(a, b) {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();

    if (titleA < titleB) {
        return -1;
    }
    if (titleA > titleB) {
        return 1;
    }

    return 0;
}
