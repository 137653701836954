import { Suggestion } from './Suggestion';
import { SuggestionType } from './SuggestionType';

import { countries } from './tourSuggestionCountries';
import { destinations } from './tourSuggestionDestinations';
import { landmarks } from './tourSuggestionLandmarks';
import { peaks } from './tourSuggestionPeaks';
import { regions } from './tourSuggestionRegions';

export const tourSuggestions: Suggestion[] = [
    {
        name: 'Anywhere',
        type: SuggestionType.Anywhere,
        isPopular: true,
    },
    ...countries,
    ...peaks,
    ...landmarks,
    ...destinations,
    ...regions,
];
