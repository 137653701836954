import classNames from 'classnames';
import CircularIconButton from '../../../common/CircularIconButton/CircularIconButton';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { selectTapTelephoneNumber } from '../../../../redux/selectors/site.selectors';
import styles from './MobilePhoneButton.module.scss';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';

export interface MobilePhoneButtonProps {
    className?: string;
}

function MobilePhoneButton({ className }: MobilePhoneButtonProps) {
    const { telephoneNumber } = useAppSelector(state =>
        selectTapTelephoneNumber(state.site)
    );

    return (
        <CircularIconButton
            className={classNames(styles.mobilePhoneButton, className)}
            icon="phoneSolid"
            as="a"
            href={`tel:${telephoneNumber}`}
            aria-label="Phone us"
            onClick={handleClick}
        />
    );

    function handleClick() {
        sendEventV2(
            Page.header,
            Area.navigation,
            InteractionItem.phoneLink,
            Action.click,
            null
        );
    }
}

export default MobilePhoneButton;
