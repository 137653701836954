import { ElementType, ReactNode, useState } from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import styles from './ExpandableSection.module.scss';

export interface ExpandableSectionProps {
    title: ReactNode;
    children: ReactNode;
    as?: ElementType;
    THeader?: ElementType;
    arrowSize?: number;
    className?: string;
}

function ExpandableSection({
    title,
    children,
    as: T = 'section',
    THeader = 'h3',
    arrowSize = 25,
    className,
    ...props
}: ExpandableSectionProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <T
            className={classNames(className, {
                [styles.expandableSection]: true,
                [styles.isOpen]: isOpen,
            })}
            {...props}
        >
            <THeader
                className={styles.header}
                onClick={() => setIsOpen(!isOpen)}
            >
                {title}
                <Icon
                    className={styles.button}
                    icon="chevron"
                    color="4a4a4a"
                    width={arrowSize}
                    height={arrowSize}
                />
            </THeader>
            <div className={styles.content}>{children}</div>
        </T>
    );
}

export default ExpandableSection;
