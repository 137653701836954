import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import SimpleSearchTool from '../../../SimpleSearchTool/SimpleSearchTool';
import Icon from '../../../common/Icon/Icon';
import {
    closeMobileSearchMenu,
    toggleMobileSearchMenu,
    closeMobileMenu,
} from '../../../../redux/actions/site.actions';
import styles from './MobileSearchMenu.module.scss';

function MobileSearchMenu({
    isMobileSearchMenuActive,
    toggleMobileSearchMenu,
    closeMobileSearchMenu,
    closeMobileMenu,
    className,
    ...props
}) {
    return (
        <nav
            className={classNames({
                [styles.mobileSearchMenu]: true,
                [styles.isActive]: isMobileSearchMenuActive,
                [className]: !!className,
            })}
            {...props}
        >
            <button
                className={styles.closeButton}
                onClick={toggleMobileSearchMenu}
                aria-label="close search"
            >
                <Icon
                    icon="times"
                    className={styles.icon}
                    height={20}
                    width={20}
                />
            </button>
            <SimpleSearchTool
                className={styles.searchTool}
                postSearch={function () {
                    closeMobileMenu();
                    closeMobileSearchMenu();
                }}
                source="mobile-header"
            >
                Search &gt;&gt;
            </SimpleSearchTool>
        </nav>
    );
}

MobileSearchMenu.propTypes = {
    isMobileSearchMenuActive: PropTypes.bool.isRequired,
    toggleMobileSearchMenu: PropTypes.func.isRequired,
    closeMobileSearchMenu: PropTypes.func.isRequired,
    closeMobileMenu: PropTypes.func.isRequired,
};

function mapStateToProps({ site }) {
    return {
        isMobileSearchMenuActive: site.isMobileSearchMenuActive,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeMobileSearchMenu: params =>
            dispatch(closeMobileSearchMenu(params)),
        toggleMobileSearchMenu: params =>
            dispatch(toggleMobileSearchMenu(params)),
        closeMobileMenu: params => dispatch(closeMobileMenu(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileSearchMenu);
