import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HeaderTop from './HeaderTop/HeaderTop';
import MobileSearchMenu from './MobileSearchMenu/MobileSearchMenu';
import styles from './Header.module.scss';
import { isCheckoutPage } from '../../../helpers/globalHelpers';

const activeMenuClass = 'mobile-menu-active';

export class HeaderV1 extends PureComponent {
    componentDidUpdate(prevProps) {
        // Check if we need to disable scroll on the body when the mobile header menu is active
        if (prevProps.isMobileMenuActive !== this.props.isMobileMenuActive) {
            if (this.props.isMobileMenuActive) {
                const body = window.document.querySelector('body');
                if (body && body.className.indexOf(activeMenuClass) === -1) {
                    body.classList.add(activeMenuClass);
                }
            } else {
                const body = window.document.querySelector(
                    `body.${activeMenuClass}`
                );
                if (body !== null) {
                    body.classList.remove(activeMenuClass);
                }
            }
        }
    }

    render() {
        return (
            <header
                className={classNames({
                    [styles.header]: true,
                    [styles.isCheckoutPage]: isCheckoutPage(
                        this.props.location.pathname
                    ),
                    className: !!this.props.className,
                })}
            >
                <HeaderTop className={styles.headerTop} />
                <MobileSearchMenu className={styles.mobileSearchMenu} />
            </header>
        );
    }
}

const mapStateToProps = ({ site }) => ({
    // bookingIsConfirmed: checkout.bookingIsConfirmed,
    isMobileMenuActive: site.isMobileMenuActive,
});

export default compose(withRouter, connect(mapStateToProps))(HeaderV1);
