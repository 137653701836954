import { SuggestionType } from './SuggestionType';
import { SuggestionRegion } from './SuggestionRegion';

export const regions: SuggestionRegion[] = [
    {
        name: 'North Africa / Middle East',
        //Can't support this region as North africa is not a supported region
        type: SuggestionType.Region,
        isPopular: false,
    },
    {
        name: 'Africa',
        isoCode2: 'AF',
        type: SuggestionType.Region,
        isPopular: false,
    },
    {
        name: 'Arctic',
        isoCode2: 'PO', //Don't have an arctic region so polar is the closest
        type: SuggestionType.Region,
        isPopular: false,
    },
    {
        name: 'Asia',
        isoCode2: 'AS',
        type: SuggestionType.Region,
        isPopular: false,
    },
    {
        name: 'Australia / Pacific',
        isoCode2: 'OC', // Fiji/Papau new guinea are counted within this region
        type: SuggestionType.Region,
        isPopular: false,
    },
    {
        name: 'Central America',
        isoCode2: 'CA',
        type: SuggestionType.Region,
        isPopular: false,
    },
    {
        name: 'Europe',
        isoCode2: 'EU',
        type: SuggestionType.Region,
        isPopular: false,
    },
    {
        name: 'Middle East',
        isoCode2: 'ME',
        type: SuggestionType.Region,
        isPopular: false,
    },
    {
        name: 'North America',
        isoCode2: 'NA',
        type: SuggestionType.Region,
        isPopular: false,
    },
    {
        name: 'Oceania',
        isoCode2: 'OC',
        type: SuggestionType.Region,
        isPopular: false,
    },
    {
        name: 'Polar',
        isoCode2: 'PO',
        type: SuggestionType.Region,
        isPopular: false,
    },
    {
        name: 'South America',
        isoCode2: 'SA',
        type: SuggestionType.Region,
        isPopular: false,
    },
];
