import classNames from 'classnames';
import Icon from '../../../common/Icon/Icon';
import styles from './MobileMenuButton.module.scss';

export interface MobileMenuButtonProps {
    className?: string;
}

function MobileMenuButton({ className }: MobileMenuButtonProps) {
    return (
        <button
            className={classNames(styles.mobileMenuButton, className)}
            aria-label="Open menu"
        >
            <Icon icon="menu" />
        </button>
    );
}

export default MobileMenuButton;
