import { TourDepartureFlight } from '@/endurance/tourDepartureFlights';
import { TourDepartureFlightState } from '@/redux/TourDepartureFlightState';

export const selectTourDepartureFlight = (
    { flights }: TourDepartureFlightState,
    tourDepartureFlightId: number
): TourDepartureFlight | null => {
    const flight = flights[tourDepartureFlightId];
    return flight ? flight : null;
};

export const selectIsRequestingTourDepartureFlight = (
    { loading }: TourDepartureFlightState,
    tourDepartureFlightId: number
) => {
    const isLoading = loading[tourDepartureFlightId];
    return isLoading ?? true;
};
