import { SyntheticEvent, useState } from 'react';
import classNames from 'classnames';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { PrimaryButton } from '../../common/Button';
import styles from './NewsletterSubscribe.module.scss';
import { FeefoBadge } from '../../common/PartnerBadges';
import { Page } from '../../../utils/analytics/enums/Page';

const url =
    'https://theadventurepeople.us14.list-manage.com/subscribe/post?u=26a4762cb1826c5a84bf42785&amp;id=35ef1081c8';

export interface NewsletterSubscribeProps {
    className?: string;
}

const NewsletterSubscribe = ({ className }: NewsletterSubscribeProps) => {
    const [email, setEmail] = useState('');
    return (
        <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => {
                const isSubmitting = status === 'sending';
                const isSuccess = status === 'success';
                const errorMessage =
                    message instanceof Error ? message.message : message;
                const isAlreadySubscribed =
                    status === 'error' &&
                    (errorMessage?.includes('already subscribed') ?? false);
                const hasError = status === 'error' && !isAlreadySubscribed;
                return (
                    <section
                        className={classNames(
                            styles.newsletterSubscribe,
                            className
                        )}
                    >
                        <div className={styles.heading}>
                            <h2>Sign up for our deals</h2>
                            <h3>
                                Stay up to date with our new latest deals &amp;
                                special offers!
                            </h3>
                        </div>
                        <form className={styles.form}>
                            <input
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                aria-label="Email"
                                placeholder="Enter your email address..."
                            />
                            <PrimaryButton
                                type="submit"
                                className={styles.submit}
                                onClick={(e: SyntheticEvent) => {
                                    e.preventDefault();
                                    subscribe({
                                        EMAIL: email,
                                    });
                                }}
                            >
                                Subscribe Now
                            </PrimaryButton>
                            <small>
                                Your email is secure and we won't send you any
                                spam.
                            </small>
                            {isSubmitting && <p>Sending...</p>}
                            {isSuccess && <p>Thanks for subscribing!</p>}
                            {isAlreadySubscribed && (
                                <p className={styles.error}>
                                    Your email is already subscribed
                                </p>
                            )}
                            {hasError && (
                                <p className={styles.error}>
                                    Oops, something went wrong! Please try
                                    again.
                                </p>
                            )}
                        </form>
                        <FeefoBadge
                            className={styles.feefo}
                            theme="light"
                            page={Page.homePage}
                        />
                    </section>
                );
            }}
        />
    );
};

export default NewsletterSubscribe;
